
import { useEffect, useState } from 'react';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { LoginStackParamList } from '../../navigators/LoginNavigator';
import firebase from '../../firebase'; // Adjust the path as necessary
import { useAuth } from '../../context/AuthContext'; // Adjust the path as necessary
import { useDeepLink } from '../../context/DeepLinkContext'; // Adjust the path as necessary
import { useCustomAlert } from '../useCustomAlert';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { 
  signInWithEmailAndPassword, 
  setPersistence,
  inMemoryPersistence,
  sendPasswordResetEmail } from "firebase/auth";
import { signOut, getAuth } from 'firebase/auth';
import { API_URL } from '@env';
import { User } from './UserTypes';
import { Audio } from 'expo-av'; // Import expo-av for audio playback

/**
 * Custom hook for handling user authentication.
 * Provides functions for login, fetching user data, and managing custom alerts.
 * @returns An object containing the loading state, login handler, custom alert functions, and alert state.
 */
export const useAuthentication = () => {
  const [loading, setLoading] = useState(false);
  const { setIsAuthenticated, setUserProfile, userProfile } = useAuth();
  const navigation = useNavigation<StackNavigationProp<LoginStackParamList>>();
  const { showCustomAlert, hideCustomAlert } = useCustomAlert();

  // States for managing CustomAlert
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  /**
   * Fetches and updates the top users data.
   * Makes an HTTP GET request to the backend API to fetch the top users data,
   * and stores the data in AsyncStorage.
   */
  const fetchAndUpdateTopUsers = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/users/topMonthlyLikes/getTopUsers`,
        {
          withCredentials: true,
        }
      );
      const topUsers = response.data;

      // Store the top users data in AsyncStorage with non-sensitive information
      const topUsersStorageObject = JSON.stringify({
          topUsers: topUsers.map((user: any) => ({ username: user.username, bio: user.bio, profilePicture: user.profilePicture })),
          lastFetched: new Date().toISOString(),
      });

      await AsyncStorage.setItem('topUsersData', topUsersStorageObject);

    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            // This is an error with the HTTP request
            const status = error.response?.status;
            const statusText = error.response?.statusText || 'No additional error message provided';
            const errorMessage = error.response?.data?.message || 'Error occurred during the request';
            console.error(`HTTP Error: ${status} ${statusText}. Message: ${errorMessage}`);
        } else {
            // This is some other error (like a network error)
            console.error('Non-HTTP Error:', error.message);
        }
    }
  };

  /**
   * Handles the login process.
   * Logs in the user using Firebase authentication, fetches user data from the backend,
   * and updates the user profile and top users data.
   * @param email The user's email address.
   * @param password The user's password.
   */
  const handleLogin = async (email: string, password: string) => {
    setLoading(true);
    try {

      // Must do this BEFORE signInWithEmailAndPassword to avoid spoiling the user's login experience
      await setPersistence(firebase.auth, inMemoryPersistence);

      // 1. sign in user with Firebase client SDK
      const userCredential = await signInWithEmailAndPassword(firebase.auth, email, password);
      // user is now logged in on the client, and we can fetch an idToken:
      const idToken = await userCredential.user.getIdToken();

      const auth0Id = userCredential.user.uid;

      // 2. Send the idToken to our server
      // Make sure to set { withCredentials: true } so the cookie is set
      const userInfoResponse = await axios.post(
        `${API_URL}/users/auth/login`,
        { idToken, auth0Id },
        { withCredentials: true }
      );

      if (userInfoResponse.data) {
          const userProfile = userInfoResponse.data.user;

          const userData : User = {
            username: userProfile.username,
            bio: userProfile.bio,
            watchedVideos: userProfile.watchedVideos,
            profilePicture: userProfile.profilePicture,
            blockedUserIds: userProfile.blockedUserIds,    
            correctSoundUrl: userProfile.correctSound,
            incorrectSoundUrl: userProfile.incorrectSound,
            followersCount: userProfile.followersCount,
            followingCount: userProfile.followingCount,
            followersUserIds: userProfile.followersUserIds,
            followingUserIds: userProfile.followingUserIds,
            uploadedVideos: userProfile.uploadedVideos,
          };

          await AsyncStorage.setItem('userProfile', JSON.stringify(userData));
          setUserProfile(userData as User);
          fetchAndUpdateTopUsers();

          // Convert userProfile object to a string before storing it
          setIsAuthenticated(true);
      }

      return true;
    } 
    catch (error: any) 
    {
        const errorMessage = error.code === 'auth/wrong-password' 
            ? 'The password is incorrect. Please try again.' 
            : error.message;
        showCustomAlert('Login Failed', errorMessage);
        console.error('Error during login:', error);
        setAlertTitle('Login Failed');
        setAlertMessage(error.message || 'An unexpected error occurred');
        setAlertVisible(true);  // Show the alert with the error message
    } 
    finally 
    {
        setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {

      // 1) Sign out from Firebase
      const Auth = getAuth();
      await signOut(Auth);
  
      // 2) Tell your server to clear the session cookie
      await axios.post(
        `${API_URL}/users/auth/logout`,
        { withCredentials: true }
      );
  
      // 3) Cleanup local state
      setIsAuthenticated(false);
      setUserProfile({} as User);
      AsyncStorage.removeItem('userProfile');
      // const parent = navigation.getParent();        // This might be the tab navigator
      // const root = parent?.getParent();            // This might be the root stack
      navigation.navigate('LoginScreen');
    } catch (error) {
      console.error(error);
    }
  };


  const isValidEmail = (email: string) => {
    // Simple regex for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleForgotPassword = async ( email: string ) => {
    if (!email || !isValidEmail(email)) 
    {
      setAlertTitle('Invalid Email');
      setAlertMessage('Please enter a valid email address to reset your password.');
      setAlertVisible(true);
      return;
    }
    try 
    {
      await sendPasswordResetEmail(firebase.auth, email);

      setAlertTitle('Check Your Email');
      setAlertMessage('If your account is valid, a link to reset your password will be sent.');
      setAlertVisible(true);
    } catch (error: any) {
      setAlertTitle('Error');
      setAlertMessage(error);
      setAlertVisible(true);
      console.error('Error sending password reset email:', error);
    }
  };

  return {
      // Login state and functions
      loading,
      handleLogin,
      handleLogout,
      showCustomAlert,
      hideCustomAlert,
      alertVisible,
      setAlertVisible,
      alertMessage,
      alertTitle,
      isValidEmail, 
      handleForgotPassword
    };
};