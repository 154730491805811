import React from 'react';
import { AlertButton, StyleSheet } from 'react-native';
import CustomAlert from '../Hooks/Alert/Alert';

interface UserActionModalProps {
  isVisible: boolean;
  onClose: () => void;
  isBlocked: boolean;
  onBlock: () => void;
  onUnblock: () => void;
  onReport: () => void;
}

const UserActionModal: React.FC<UserActionModalProps> = ({
  isVisible,
  onClose,
  isBlocked,
  onBlock,
  onUnblock,
  onReport,
}) => {
  // Modified handlers to ensure proper function calling sequence
  const handleBlock = () => {
    isBlocked ? onUnblock() : onBlock();
  };
  
  const handleReport = () => {
    onReport();
  };

  // Prepare the buttons for the CustomAlert
  const buttons: AlertButton[] = [
    {
      text: isBlocked ? 'Unblock User' : 'Block User',
      onPress: handleBlock,
      style: 'default',
    },
    {
      text: 'Report User',
      onPress: handleReport,
      style: 'default',
    },
    {
      text: 'Cancel',
      onPress: () => {}, // Empty function as onClose is already called in Alert component
      style: 'cancel',
    },
  ];

  return (
    <CustomAlert
      isVisible={isVisible}
      title="User Actions"
      message="Select an action to perform"
      buttons={buttons}
      onClose={onClose}
    />
  );
};

export default UserActionModal;