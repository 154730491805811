import React, { useState, useEffect } from 'react';
import { Text, Pressable, Platform, ListRenderItemInfo } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { View } from '../../context/SafeComponents';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '../../context/AuthContext';
import { useAuthentication } from '../../hooks/user/useAuthentication';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPowerOff } from '../../optimizations/OptimizedIcons';
import { faInfoCircle } from '../../optimizations/OptimizedIcons';
import { StackParamList } from '../../navigators/navigationTypes';
import UsersListModal from '../UsersListModal/UsersListModal';
import { RouteProp } from '@react-navigation/native';
import Image from '../UtilityComponents/UniversalImage';
import CustomAlert from '../Hooks/Alert/Alert';
import { useRefetch } from '../../context/RefetchContext';
import { globalEvents } from '../../context/GlobalEvents';
import InfoModal from '../InfoModal/InfoModal';
import ProfilePictureModal from '../ProfilePictureModal/ProfilePictureModal';
import VideoGallery from '../VideoGallery/VideoGallery';
import styles from './style';
import { useGetUserInfo } from '../../hooks/user/useGetUserInfo';
import { useCustomAlert } from '../../hooks/useCustomAlert';

// Define the type for the props expected by UserViewOther component
type UserViewOtherProps = {
  route: RouteProp<StackParamList, 'UserViewSelf'>;
};

import EditProfileModal from '../EditProfileModal/EditProfileModal';
import CustomButton from '../CustomButton/CustomButton';

const UserViewSelf: React.FC<UserViewOtherProps> = ({ route }) => {

  const navigation = useNavigation<StackNavigationProp<StackParamList>>();
  // Might want to rename or make this one thing
  const { userProfile } = useAuth();
  const { handleLogout } = useAuthentication();
  const [showFollowersModal, setShowFollowersModal] = useState(false); // State to show/hide followers modal
  const [showFollowingModal, setShowFollowingModal] = useState(false); // State to show/hide following modal
  const [isInfoModalVisible, setInfoModalVisible] = useState(false);
  const [isProfileModalVisible, setProfileModalVisible] = useState(false);
  const { refetchTrigger } = useRefetch(); // Destructure the triggerRefetch function from the context

  const { loading,
          followers, following, followersCount, followingCount, 
          isRefreshing, uploadedVideos, profilePicture,
          editMode, setEditMode, newBio, setNewBio,
          fetchUploadedVideos, fetchCountsAndLists,
          didUpload,
          updateBio, pickImageAndUpload, editBioMode,
          setEditBioMode,
          setProfilePictureImage } = useGetUserInfo();

  const toggleInfoModal = () => setInfoModalVisible(!isInfoModalVisible);

  const { showCustomAlert, hideCustomAlert, alertProps } = useCustomAlert();

  useEffect(() => {
    fetchUploadedVideos();
  }, [refetchTrigger]); // Depend on the refetchTrigger value

  useEffect(() => {
    fetchUploadedVideos();
    fetchCountsAndLists();
  }, [userProfile?.username]);

  useEffect(() => {
    if (userProfile?.username) {
      setProfilePictureImage(); // Only fetch if the username is available
    }
  }, [userProfile?.username, didUpload]); // Depend on the username

  useEffect(() => {
    const handleVideoUploaded = () => {
      fetchUploadedVideos();
    };

    // Listen for the videoUploaded event
    globalEvents.on('videoUploaded', handleVideoUploaded);

    // Cleanup the listener when the component unmounts
    return () => {
      globalEvents.off('videoUploaded', handleVideoUploaded);
    };
  }, []); // This effect runs only once when the component mounts

  useEffect(() => {
    if (userProfile) {
      setNewBio(userProfile.bio || '');
    }
  }, [userProfile]);

  const renderCounts = () => (
    <View style={styles.countsContainer}>
      <Pressable onPress={() => setShowFollowersModal(true)}>
        <Text style={{color: 'white', fontFamily: 'PressStart2P_400Regular'}}>{followersCount} Followers</Text>
      </Pressable>
      <Pressable onPress={() => setShowFollowingModal(true)}>
        <Text style={{color: 'white', fontFamily: 'PressStart2P_400Regular'}}>{followingCount} Following</Text>
      </Pressable>
    </View>
  );

  const renderEditProfileButton = () => {
    const buttonColor = Platform.OS === 'ios' ? 'white' : 'blue';
    const button = (
      <CustomButton
        title="Edit Profile"
        buttonStyle={ { color: buttonColor } }
        onPress={() => setEditMode(true)}
      />
    );
    return Platform.OS === 'ios' ? (
      <View style={{ margin: 10 }}>{button}</View>
    ) : (
      button
    );
    return null;
  };

  const renderProfileImage = () => {
    return (
      <View style={styles.profileImageContainer}>
        <Pressable onPress={() => setProfileModalVisible(true)} style={styles.imageWrapper}>
          <Image
            source={loading ? require('../../assets/images/avatar.jpg') : profilePicture}
            style={styles.avatar}
          />
        </Pressable>
      </View>
    );
  };

  const renderLogoutButton = () => {
    const showLogoutDialog = () => {
      showCustomAlert(
        "Logout",
        "Are you sure you want to logout?",
        [
          { text: "Cancel", onPress: () => hideCustomAlert() },
          { text: "Yes", onPress: handleLogout }
        ]
      );
    };
  
    return (
      <View style={styles.logoutContainer}>
        <Pressable onPress={showLogoutDialog} style={styles.logoutButton}>
          <FontAwesomeIcon icon={faPowerOff} size={20} color="white"/>
        </Pressable>
        <CustomAlert {...alertProps} />
      </View>
    );
  };


  const cancelEdit = () => {
    setEditBioMode(false);
    setEditMode(false);
    setNewBio(userProfile?.bio ?? ''); // Reset the bio to the last saved state
  };

  const renderBioSection = () => {
    return (
      <View style={styles.bioContainer}>
        <Text style={styles.bio}>{userProfile?.bio}</Text>
      </View>
    );
  };

  const navigateToViewer = (videoId: string) => {
    if(userProfile){
      navigation.navigate('QuizViewerProfile', {videoIds: [videoId], initialVideoId: videoId, username: userProfile.username});
    }
  }

  // Function to render each uploaded video's thumbnail
  const renderVideoThumbnail = ({ item }: ListRenderItemInfo<string>) => {
    
    const thumbnailUri = `https://storage.googleapis.com/clipdle_videos_thumbnails/${item}_thumbnail.jpeg`;
    return (
      <Pressable onPress={() => navigateToViewer(item)} style={styles.clipImageContainer}>
        <Image
          source={{ uri: thumbnailUri }}
          defaultSource={require('../../assets/images/Ex1.jpg')} // Fallback image
          style={styles.clipImage}
        />
      </Pressable>
    );
  };
  
  return (
    <View style={styles.container} >
      <View style={styles.header}>
        {renderProfileImage()}
        {renderLogoutButton()}
        <Pressable onPress={toggleInfoModal} style={styles.infoButton}>
          <FontAwesomeIcon icon={faInfoCircle} size={20} color="white" />
        </Pressable>
      </View>
      <View style={styles.profileDetails}>
        <Text style={styles.username}>{userProfile?.username}</Text>
        {renderBioSection()}
        {renderEditProfileButton()}
      </View>

      <EditProfileModal
        visible={editMode}
        onClose={cancelEdit}
        profilePicture={profilePicture}
        loading={loading}
        newBio={newBio}
        setNewBio={setNewBio}
        updateBio={updateBio}
        pickImageAndUpload={pickImageAndUpload}
        editBioMode={editBioMode}
        setEditBioMode={setEditBioMode}
        userProfile={userProfile || undefined}
      />

      <ProfilePictureModal
        visible={isProfileModalVisible}
        onClose={() => setProfileModalVisible(false)}
        imageSource={loading ? require('../../assets/images/avatar.jpg') : profilePicture}
      />

      {renderCounts()}

      {/* Followers Modal */}
      {followers.length > 0 && (
        <UsersListModal
          users={followers}
          isVisible={showFollowersModal}
          toggleModal={() => setShowFollowersModal(false)}
        />
      )}

      {following.length > 0 && (
        <UsersListModal
          users={following}
          isVisible={showFollowingModal}
          toggleModal={() => setShowFollowingModal(false)}
        />
      )}

      <InfoModal visible={isInfoModalVisible} hideModal={toggleInfoModal} />

      <View style={{flex: 1, width: '100%'}}>
        <VideoGallery
          videos={uploadedVideos}
          onVideoPress={navigateToViewer}
          onRefresh={fetchUploadedVideos}
          refreshing={isRefreshing}
        />
      </View>
    </View>
  );
};

export default UserViewSelf;
