import React, { useState, useEffect } from 'react';
import styles from './style';
import { useNavigation } from '@react-navigation/native';
import { View } from '../../context/SafeComponents';
import { Pressable, TextInput, FlatList, Text, } from 'react-native';
import axios from 'axios';
import { StackNavigationProp } from '@react-navigation/stack';
import { StackParamList } from '../../navigators/navigationTypes';
import Image from '../UtilityComponents/UniversalImage';
import CustomAlert from '../Hooks/Alert/Alert';

import { useSearch } from '../../hooks/user/useSearch';
import { User } from '../../hooks/user/UserTypes';

import { API_URL } from '@env';

const UserSearch: React.FC = () => {

  const [query, setQuery] = useState('');
  const [imageErrors, setImageErrors] = useState<Set<string>>(new Set());

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [navigationError, setNavigationError] = useState(false);

  const { users, setUsers, setTopUsers, topUsers, handleSearch, fetchTopUsers } = useSearch();

  const navigation = useNavigation<StackNavigationProp<StackParamList>>();

  useEffect(() => {
    fetchTopUsers(); // Fetch top users when component mounts
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (query.trim() !== '') {
        handleSearch(query);
        setTopUsers([]); // Clear top users when search begins
      } else {
        setUsers([]); // Reset the search results if the query is empty
        fetchTopUsers(); // Fetch top users again when the search query is cleared
      }
    }, 500); // 500 milliseconds debounce period

    return () => clearTimeout(debounceTimeout); // Clear timeout on component unmount
  }, [query]);

  const handleUserPress = async (userProfile: User) => {
    try {
      navigation.navigate('UserView', {
        username: userProfile.username,
      });
    } catch (error: any) {
      console.error(error);
      setAlertTitle('Navigation Error');
      setAlertMessage('Failed to navigate to user profile. The user you may be banned, recently deleted their profile, or we may be experiencing network issues.');
      setNavigationError(true);
      setAlertVisible(true);
    }
  };

  const renderItem = ({ item }: { item: User }) => {
    return (
      <Pressable onPress={() => handleUserPress(item)}>
        <View style={styles.userItem}>
          <Image
            source={ { uri: item.profilePicture } }
            style={styles.commentAvatar}
          />
          <Text style={styles.userText}>{item.username}</Text>
        </View>
      </Pressable>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.searchBar}>
        <TextInput
          style={styles.input}
          value={query}
          onChangeText={setQuery}
          cursorColor={'white'}
          placeholder="Search for users..."
          placeholderTextColor={'white'}
          autoCapitalize="none"
        />
      </View>

      {/* Top Users List */}
      {topUsers.length > 0 && query === '' && (
    <View>
        <Text style={styles.sectionTitle}>Top Monthly Users</Text>
        {/* Render top users */}
        {topUsers.map((user) => {
            return (
                <Pressable key={user.username} onPress={() => handleUserPress(user)}>
                    <View style={styles.userItem}>
                        <Image
                            source={{ uri: user.profilePicture }}
                            style={styles.commentAvatar}
                        />
                        <Text style={styles.userText}>{user.username}</Text>
                    </View>
                </Pressable>
            );
        })}

        {navigationError && (
            <CustomAlert
                isVisible={alertVisible}
                title={alertTitle}
                message={alertMessage}
                buttons={[
                    {
                        text: 'OK',
                        onPress: () => {
                            setAlertVisible(false);
                            setNavigationError(false);
                        },
                    },
                ]}
                onClose={() => {
                    setAlertVisible(false);
                    setNavigationError(false);
                }}
            />
        )}
    </View>
)}

      <FlatList
        data={users}
        keyExtractor={(item) => item.username}
        renderItem={renderItem}
      />

    </View>
  );
};



export default UserSearch;