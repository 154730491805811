import React, { useRef, useState } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Platform, StyleSheet, Animated } from 'react-native';
import { View } from '../context/SafeComponents';
import UserStartNavigator, { userStartStackRef } from './UserStartNavigator';
import QuizStartNavigator, { quizStackRef } from './QuizStartNavigator';
import SubmitClipNavigator, { submissionStackRef } from './SubmitClipNavigator';
import UserSearchNavigator, { userSearchStackRef } from './UserSearchNavigator';
// Import optimized icons
import { 
  FontAwesomeIcon, 
  faFilm, 
  faPlusCircle, 
  faSearch, 
  faPerson 
} from '../optimizations/OptimizedIcons';
import { StackParamList } from './navigationTypes';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
// Import lazy-loaded screens
import  UserView from '../components/UserView/UserView';
import UserViewOther from '../components/UserViewOther/UserViewOther';
import QuizViewerProfile from '../components/QuizViewerProfile/QuizViewerProfile';
import { StackActions } from '@react-navigation/native';

const Tab = createBottomTabNavigator<StackParamList>();

let topMargin: number;

if (Platform.OS === 'ios') {
  topMargin = hp('2%');
} else {
  topMargin = 0;
}

// Extracted animation function to reduce component re-renders
const createWaveAnimation = (value: Animated.Value) => {
  return Animated.timing(value, {
    toValue: 1,
    duration: 300,
    useNativeDriver: true,
  });
};

function MainTabNavigator() {
  const waveAnimations = {
    QuizStart: useRef(new Animated.Value(0)).current,
    SubmitClipStart: useRef(new Animated.Value(0)).current,
    UserSearchStart: useRef(new Animated.Value(0)).current,
    UserViewSelfStart: useRef(new Animated.Value(0)).current,
  };

  // Memoize the AnimatedIcon component to prevent re-renders
  const AnimatedIcon = React.memo(({ 
    icon, 
    color, 
    size, 
    waveAnimation 
  }: { 
    icon: any; 
    color: string; 
    size: number; 
    waveAnimation: Animated.Value 
  }) => {
    const scale = waveAnimation.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [1, 1.2, 1],
    });

    const opacity = waveAnimation.interpolate({
      inputRange: [0, 0.5, 1],
      outputRange: [0, 1, 0],
    });

    return (
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Animated.View
          style={{
            position: 'absolute',
            width: size * 2,
            height: size * 2,
            borderRadius: size,
            borderWidth: 2,
            borderColor: color,
            opacity,
            transform: [{ scale }],
          }}
        />
        <FontAwesomeIcon style={{ top: topMargin }} icon={icon} size={size} color={color} />
      </View>
    );
  });

  const animateTab = (toTab: keyof typeof waveAnimations) => {
    // Reset all animations
    (Object.keys(waveAnimations) as Array<keyof typeof waveAnimations>).forEach((tab) => {
      waveAnimations[tab].setValue(0);
    });

    // Animate the wave effect to the new tab
    createWaveAnimation(waveAnimations[toTab]).start();
  };

  const getTabBarActiveTintColor = (focused: boolean) => {
    return focused ? '#007AFF' : 'gray';
  };

  // Create navigation handlers once to avoid recreating functions on each render
  const navigationHandlers = {
    QuizStart: React.useCallback(({ navigation }: any) => ({
      tabPress: (e: any) => {
        e.preventDefault();
        animateTab('QuizStart');
        setTimeout(() => {
          quizStackRef.current?.dispatch(StackActions.popToTop());
          navigation.navigate('QuizStart');
        }, 300);
      },
    }), []),
    
    SubmitClipStart: React.useCallback(({ navigation }: any) => ({
      tabPress: (e: any) => {
        e.preventDefault();
        animateTab('SubmitClipStart');
        setTimeout(() => {
          submissionStackRef.current?.dispatch(StackActions.popToTop());
          navigation.navigate('SubmitClipStart');
        }, 300);
      },
    }), []),
    
    UserSearchStart: React.useCallback(({ navigation }: any) => ({
      tabPress: (e: any) => {
        e.preventDefault();
        animateTab('UserSearchStart');
        setTimeout(() => {
          userSearchStackRef.current?.dispatch(StackActions.popToTop());
          navigation.navigate('UserSearchStart');
        }, 300);
      },
    }), []),
    
    UserViewSelfStart: React.useCallback(({ navigation }: any) => ({
      tabPress: (e: any) => {
        e.preventDefault();
        animateTab('UserViewSelfStart');
        setTimeout(() => {
          userStartStackRef.current?.dispatch(StackActions.popToTop());
          navigation.navigate('UserViewSelfStart');
        }, 300);
      },
    }), []),
  };

  return (
    <View style={styles.container}>
      <Tab.Navigator
        screenOptions={{
          headerShown: false,
          tabBarActiveTintColor: '#007AFF',
          tabBarInactiveTintColor: 'gray',
          tabBarStyle: styles.tabBar,
        }}
      >
        <Tab.Screen
          name="QuizStart"
          component={QuizStartNavigator}
          options={{
            title: 'Quiz',
            unmountOnBlur: true,
            tabBarLabel: () => null,
            tabBarIcon: ({ focused, color, size }) => (
              <AnimatedIcon
                icon={faFilm}
                color={getTabBarActiveTintColor(focused)}
                size={size}
                waveAnimation={waveAnimations.QuizStart}
              />
            ),
          }}
          listeners={navigationHandlers.QuizStart}
        />

        <Tab.Screen
          name="SubmitClipStart"
          component={SubmitClipNavigator}
          options={{
            title: 'Upload Clip',
            tabBarLabel: () => null,
            unmountOnBlur: true,
            tabBarIcon: ({ focused, color, size }) => (
              <AnimatedIcon
                icon={faPlusCircle}
                color={getTabBarActiveTintColor(focused)}
                size={size}
                waveAnimation={waveAnimations.SubmitClipStart}
              />
            ),
          }}
          listeners={navigationHandlers.SubmitClipStart}
        />

        <Tab.Screen
          name="UserSearchStart"
          component={UserSearchNavigator}
          options={{
            tabBarLabel: () => null,
            unmountOnBlur: true,
            tabBarIcon: ({ focused, color, size }) => (
              <AnimatedIcon
                icon={faSearch}
                color={getTabBarActiveTintColor(focused)}
                size={size}
                waveAnimation={waveAnimations.UserSearchStart}
              />
            ),
          }}
          listeners={navigationHandlers.UserSearchStart}
        />

        <Tab.Screen
          name="UserViewSelfStart"
          component={UserStartNavigator}
          options={{
            tabBarLabel: () => null,
            unmountOnBlur: true,
            tabBarIcon: ({ focused, color, size }) => (
              <AnimatedIcon
                icon={faPerson}
                color={getTabBarActiveTintColor(focused)}
                size={size}
                waveAnimation={waveAnimations.UserViewSelfStart}
              />
            ),
          }}
          listeners={navigationHandlers.UserViewSelfStart}
        />

        <Tab.Screen
          name="QuizViewerProfile"
          component={QuizViewerProfile}
          options={{ 
            tabBarButton: () => <View />,
            unmountOnBlur: true,
          }}
        />

        <Tab.Screen
          name="UserViewOther"
          component={UserViewOther}
          options={{ 
            tabBarButton: () => <View />,
            unmountOnBlur: true,
          }}
        />

        <Tab.Screen
          name="UserView"
          component={UserView}
          options={{ 
            tabBarButton: () => <View />,
            unmountOnBlur: true,
          }}
        />
      </Tab.Navigator>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    marginBottom: 55,
  },
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  tabBar: {
    zIndex: 4,
    width: '100%',
    height: 55,
    backgroundColor: 'rgba(24, 24, 24, 1)',
    justifyContent: 'space-evenly',
    borderTopWidth: 0,
    elevation: 0,
    shadowOpacity: 0,
  },
});

export default MainTabNavigator;