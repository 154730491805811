import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { StackParamList } from './navigationTypes';
// Import lazy-loaded components for better code splitting
import { 
  Login, 
  SignUp, 
  ForgotPassword, 
  QuizViewerProfile 
} from './LazyNavigatorComponents';

// Define the types for the navigation parameters
export type LoginStackParamList = {
    LoginScreen: undefined;
    SignUpScreen: undefined;
    ForgotPassword: undefined;
    QuizViewerProfile: {
      videoIds: string[]; 
      initialVideoId: string;
      username: string;
  };
};

const LoginStack = createStackNavigator<StackParamList>();

function LoginNavigator() {
    return (
      <LoginStack.Navigator screenOptions={{ headerShown: false }} initialRouteName="LoginScreen">
        <LoginStack.Screen name="LoginScreen" component={Login} />
        <LoginStack.Screen name="SignUpScreen" component={SignUp} />
        <LoginStack.Screen name="ForgotPassword" component={ForgotPassword} />
        <LoginStack.Screen name="QuizViewerProfile" component={QuizViewerProfile} />
      </LoginStack.Navigator>
    );
  }

export default LoginNavigator;