import React, { useState } from 'react';
import { Text, TextInput, Pressable, Alert } from 'react-native';
import { View } from '../../context/SafeComponents';
import Modal from '../Hooks/Modal/Modal';
import styles from './style';

interface ReportModalProps {
  isVisible: boolean;
  onClose: () => void;
  onReportCopyright: () => void;
  onReportOther: (details?: string) => void;
  username: string | null;
}

const ReportModal: React.FC<ReportModalProps> = ({
  isVisible,
  onClose,
  onReportCopyright,
  onReportOther,
  username,
}) => {
  const [selectedReason, setSelectedReason] = useState<string>('');
  const [additionalDetails, setAdditionalDetails] = useState<string>('');

  const reportOptions = [
    "It's spam",
    "Nudity or sexual content",
    "Hate speech",
    "Violence or dangerous acts",
    "Harassment or bullying",
    "Other",
  ];

  const handleReport = () => {
    if (!selectedReason) {
      Alert.alert('Report', 'Please select a reason for reporting.');
      return;
    }

    if (selectedReason === "Copyright violation") {
      onReportCopyright();
    } else {
      onReportOther(additionalDetails);
    }

    onClose(); // Close the modal after reporting
  };

  return (
    <Modal isOpen={isVisible} title="Report Content" onClose={onClose}>
      {/* Wrap everything in a View with the desired background color */}
      <View style={styles.fullModalContainer}>
        <Pressable style={styles.closeIcon} onPress={onClose}>
          <Text style={{ color: 'white', fontSize: 18 }}>X</Text>
        </Pressable>

        <View style={styles.modalContent}>
          {reportOptions.map((option, index) => (
            <Pressable
              key={index}
              style={[
                styles.option,
                selectedReason === option && styles.selectedOption,
              ]}
              onPress={() => setSelectedReason(option)}
            >
              <Text style={styles.optionText}>{option}</Text>
            </Pressable>
          ))}

          {selectedReason === 'Other' && (
            <TextInput
              style={styles.input}
              onChangeText={setAdditionalDetails}
              value={additionalDetails}
              placeholder="Please provide more details"
              placeholderTextColor="#ccc"
              multiline
            />
          )}

          <Pressable style={styles.reportButton} onPress={handleReport}>
            <Text style={styles.reportButtonText}>Report</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

export default ReportModal;
