
import { useState } from 'react';
import axios from 'axios';
import { User } from './UserTypes';

import { API_URL } from '@env';

/**
 * Custom hook for handling user authentication.
 * Provides functions for login, fetching user data, and managing custom alerts.
 * @returns An object containing the loading state, login handler, custom alert functions, and alert state.
 */
export const useGetUser = ( username: string ) => {
    const [loading, setLoading] = useState(false);

    // Ensure fetchUploadedVideos is accessible in the scope where you need it
    const fetchUser = async () => {
        setLoading(true); // Start refreshing
        try {
            const response = await axios.get(
                `${API_URL}/users/find/${username}`, 
                {
                    withCredentials: true,
                }
            );

            const userProfileResponse = response.data;

            const userData : User = {
                username: userProfileResponse.username,
                bio: userProfileResponse.bio,
                watchedVideos: userProfileResponse.watchedVideos,
                profilePicture: userProfileResponse.profilePicture,
                blockedUserIds: userProfileResponse.blockedUserIds,    
                correctSoundUrl: userProfileResponse.correctSound,
                incorrectSoundUrl: userProfileResponse.incorrectSound,
                followersCount: userProfileResponse.followersCount,
                followingCount: userProfileResponse.followingCount,
                followersUserIds: userProfileResponse.followersUserIds,
                followingUserIds: userProfileResponse.followingUserIds,
                uploadedVideos: userProfileResponse.uploadedVideos,
            };

            return userData as User;
        } catch (error) {
            console.error('Error fetching uploaded videos:', error);
        } finally {
            setLoading(false); // Stop
        }
    };

    return {
        // Login state and functions
        loading,
        fetchUser,
        //...
    };
};