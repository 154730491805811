import React, { useState, useEffect, useRef, useCallback, memo, useMemo } from 'react';
import { 
  View, 
  FlatList, 
  Dimensions, 
  ActivityIndicator, 
  TouchableOpacity, 
  StatusBar, 
  Platform, 
  ViewStyle, 
  Text,
  Animated 
} from 'react-native';
import { Video, ResizeMode, AVPlaybackStatus } from 'expo-av';
import QuizOverlay from '../QuizOverlay/QuizOverlay';
import { RouteProp, useIsFocused } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowLeft } from '../../optimizations/OptimizedIcons';
import { faRepeat } from '../../optimizations/OptimizedIcons';
import { faPlay } from '../../optimizations/OptimizedIcons';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { StackParamList } from '../../navigators/navigationTypes';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styles from './style';
import { Audio, InterruptionModeIOS, InterruptionModeAndroid } from 'expo-av';
import {
  ProgressData,
  QuizProps,
  VideoResponseData,
  VideoOrEndMessage,
  VideoData,
  ExtendedViewToken,
  WatchedVideoData,
} from '../Quiz/QuizTypes';
import { API_URL } from '@env';
import axios from 'axios';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

type QuizViewerProfileProps = {
    route: RouteProp<StackParamList, 'QuizViewerProfile'>;
};

// Interface for VideoItemProps
interface VideoItemProps {
  item: VideoData;
  index: number;
  videoStyle: ViewStyle;
  videoRef: React.RefObject<Video>;
  isLoading: boolean;
  showPlayButton: boolean;
  showReplayButton: boolean;
  isCommentsVisible: boolean;
  username: string;
  handleReplay: () => void;
  handlePlay: () => void;
  handleAnswerSelect: (answer: string, index: number, isCorrect: boolean, videoItem: VideoData) => void;
  handleOnPlaybackStatusUpdate: (videoId: string) => (status: AVPlaybackStatus) => void;
  updateWatchedVideo: (videoId: string, updates: Partial<WatchedVideoData>) => void;
  handleCommentsToggle: (isVisible: boolean) => void;
  fadeOutPlayButton: () => void;
  fadeInPlayButton: () => void;
  playButtonAnim: Animated.Value;
  setIsPaused: (isPaused: boolean) => void;
  dynamicStyles: {
    videoContainer: any;
    fullScreenVideo: any;
  };
  questionAnswered: boolean;
}

const VID_HEIGHT = Dimensions.get('window').height - (StatusBar.currentHeight || 0);

// Animated play button component
const AnimatedPlayButton = memo(({ playButtonAnim, handlePlay }: { playButtonAnim: Animated.Value, handlePlay: () => void }) => (
  <Animated.View style={[styles.playButtonContainer, { opacity: playButtonAnim }]}>
    <TouchableOpacity onPress={handlePlay}>
      <FontAwesomeIcon icon={faPlay} size={50} color="#FFF" />
    </TouchableOpacity>
  </Animated.View>
));

// Play button component
const PlayButton = memo(({ handlePlay }: { handlePlay: () => void }) => (
  <TouchableOpacity
    style={styles.playButtonContainer}
    onPress={handlePlay}
  >
    <FontAwesomeIcon icon={faPlay} size={50} color="#FFF" />
  </TouchableOpacity>
));

// Replay button component
const ReplayButton = memo(({ handleReplay }: { handleReplay: () => void }) => (
  <TouchableOpacity
    style={styles.replayButtonContainer}
    onPress={handleReplay}
  >
    <FontAwesomeIcon icon={faRepeat} size={50} color="#FFF" />
  </TouchableOpacity>
));

// Memoized video item component
const MemoizedVideoItem = memo((props: VideoItemProps) => {
  const {
    item,
    videoStyle,
    videoRef,
    isLoading,
    showPlayButton,
    showReplayButton,
    isCommentsVisible,
    username,
    handleReplay,
    handlePlay,
    handleAnswerSelect,
    handleOnPlaybackStatusUpdate,
    updateWatchedVideo,
    handleCommentsToggle,
    fadeOutPlayButton,
    playButtonAnim,
    setIsPaused,
    dynamicStyles,
    questionAnswered,
  } = props;

  const onLoadCallback = useCallback(() => {
    setIsPaused(false);
  }, [setIsPaused]);

  return (
    <View style={dynamicStyles.videoContainer}>
      <VideoPlayer
        source={{ uri: item.source }}
        style={videoStyle as ViewStyle}
        videoStyle={videoStyle as ViewStyle}
        videoId={item.id}
        isLooping={true}
        shouldPlay={true}
        resizeMode={ResizeMode.COVER}
        onLoad={onLoadCallback}
        onPlaybackStatusUpdate={handleOnPlaybackStatusUpdate(item.id)}
        ref={videoRef}
        commentsVisible={isCommentsVisible}
      />

      {isLoading && (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#00ff00" />
        </View>
      )}

      <AnimatedPlayButton playButtonAnim={playButtonAnim} handlePlay={handlePlay} />

      {showPlayButton && <PlayButton handlePlay={handlePlay} />}
      {showReplayButton && <ReplayButton handleReplay={handleReplay} />}

      <QuizOverlay 
        mongoId={item._id}
        question={item.question as string}
        GCSId={item.id}
        isBlocked={false}
        answers={item.answers || []}
        onAnswerSelect={(ans, idx, correct) =>
          handleAnswerSelect(ans, idx, correct, item)
        }
        correctAnswer={item.correctAnswer}
        uploaderUsername={username}
        isFromProfile={true}
        answersModalVisible={false}
        setAnswersModalVisible={() => {}}
        onVideoInteraction={(updates) => updateWatchedVideo(item.id, updates)}
        onCommentsToggle={handleCommentsToggle}
        onAnswersModalStateChange={() => {}}
      />
    </View>
  );
}, (prevProps, nextProps) => {
  // Custom comparison function - only re-render when these props change
  return (
    prevProps.item.id === nextProps.item.id &&
    prevProps.isCommentsVisible === nextProps.isCommentsVisible &&
    prevProps.showReplayButton === nextProps.showReplayButton &&
    prevProps.showPlayButton === nextProps.showPlayButton &&
    prevProps.isLoading === nextProps.isLoading && 
    prevProps.questionAnswered === nextProps.questionAnswered
  );
});

const QuizViewerProfile: React.FC<QuizViewerProfileProps> = ({ route }) => {
  const [videos, setVideos] = useState<VideoData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [showReplayButton, setShowReplayButton] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const questionAnswered = useRef(false);
  const [currentVideo, setCurrentVideo] = useState<VideoData | null>(null);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [watchedVideos, setWatchedVideos] = useState<WatchedVideoData[]>([]);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);

  const { videoIds, initialVideoId, username } = route.params;

  const flatListRef = useRef<FlatList<VideoData> | null>(null);
  const videoRef = useRef<Video>(null);

  // MOVED INSIDE COMPONENT: hooks need to be inside the component function
  const insets = useSafeAreaInsets();
  const bottomInset = insets.bottom || 0;

  const WINDOW_HEIGHT = useMemo(() => 
    Platform.OS === 'android' && Platform.Version >= 29
      ? Dimensions.get('window').height + (StatusBar.currentHeight || 0)
      : Dimensions.get('window').height,
    []
  );

  const tabBarHeight = 55;
  const availableHeight = useMemo(() => 
    WINDOW_HEIGHT - (StatusBar.currentHeight || 0) - tabBarHeight - bottomInset,
    [WINDOW_HEIGHT, bottomInset]
  );

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const videoAspectRatio = 9 / 16;

  const navigation = useNavigation();

  // Dynamically create styles that depend on availableHeight
  const dynamicStyles = useMemo(() => ({
    videoContainer: {
      width: '100%',
      height: availableHeight,
      justifyContent: 'center' as const,
      alignItems: 'center' as const,
    },
    fullScreenVideo: {
      aspectRatio: videoAspectRatio,
      width: '100%',
      height: '100%' as any, // Cast to any to avoid type issues with DimensionValue
      alignSelf: 'center' as const,
    },
  }), [availableHeight, videoAspectRatio]);

  /****************************************
   *  Animated Play Button
   ****************************************/
  // Animated value to control the play button's opacity.
  const playButtonAnim = useRef(new Animated.Value(0)).current;

  const fadeInPlayButton = useCallback(() => {
    Animated.timing(playButtonAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }, [playButtonAnim]);

  const fadeOutPlayButton = useCallback(() => {
    Animated.timing(playButtonAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }, [playButtonAnim]);

  const handlePause = useCallback(() => {
    const player = videoRef.current;
    if (player) {
      return player.pauseAsync();
    }
  }, []);

  const handlePlay = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.playAsync().then(() => {
        fadeOutPlayButton();
      });
      setShowPlayButton(false);
    }
  }, [fadeOutPlayButton]);

  // When the user taps on the video, pause it and fade in the play button.
  const handleVideoPress = useCallback((videoId: string) => {
    const player = videoRef.current;
    if (player) {
      handlePause()?.then(() => {
        fadeInPlayButton();
      });
    }
  }, [fadeInPlayButton]);

  // Function to fetch specific video data
  const fetchVideoData = useCallback(async (id: string) => {
    try {
      const response = await axios.get(`${API_URL}/videos/${id}`,
        {
          withCredentials: true,
        }
      );
      const data: VideoData = await response.data;
      return data;
    } catch (error) {
      console.error('Failed to fetch video:', error);
    }
  }, []);

  const isFocused = useIsFocused();

  // Adjust the onAnswerSelect function
  const handleAnswerSelect = useCallback(
    (answer: string, index: number, isCorrect: boolean, videoItem: VideoData) => {
      questionAnswered.current = true;
      setShowReplayButton(false);
  
      // Resume playing the video
      const player = videoRef.current;
      if (player && isFocused) {
        try {
          player.playAsync();
        } catch (e) {
          console.error('Error calling playAsync:', e);
        }
      }
    },
    [isFocused, videoRef] // Add videoRef to dependency array for completeness
  );

  useEffect(() => {
    // This effect will run whenever questionAnswered changes
    if (questionAnswered.current) {
      const player = videoRef.current;
      if (player && isFocused) {
        try {
          player.playAsync().catch(err => 
            console.error("Error playing video after question answered:", err)
          );
        } catch (e) {
          console.error('Error calling playAsync in effect:', e);
        }
      }
    }
  }, [questionAnswered, isFocused, videoRef]);

  useEffect(() => {
    if (currentVideo && currentVideo.id) {
      if(isPaused) {
        handlePause();
      } else {
        handlePlay();
      } 
    }
  }, [isPaused]);

  useEffect(() => {
    (async () => {
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
        interruptionModeIOS: InterruptionModeIOS.DoNotMix,
        playsInSilentModeIOS: true,
        interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
        shouldDuckAndroid: true,
        staysActiveInBackground: false,
      });
    })();
  }, []);

  const scrollToIndexFailed = useCallback((info: any) => {
    const wait = new Promise(resolve => setTimeout(resolve, 500));
    wait.then(() => {
      flatListRef.current?.scrollToIndex({ index: info.index, animated: true });
    });
  }, []);

  /**************************************
   *   updateWatchedVideo
   **************************************/
  const updateWatchedVideo = useCallback((videoId: string, updates: Partial<WatchedVideoData>) => {
    setWatchedVideos((prev) =>
      prev.map((wv) => {
        if (wv.videoId === videoId) {
          return { ...wv, ...updates };
        }
        return wv;
      })
    );
    // Optionally sync with backend
  }, []);

  // Track comments visibility
  const handleCommentsToggle = useCallback((isVisible: boolean) => {
    setIsCommentsVisible(isVisible);
  }, []);

  const onProgress = useCallback((data: ProgressData, videoId: string) => {
    setCurrentTime(data.currentTime);

    // Check if currentVideo and pausePoint are defined
    if (currentVideo && currentVideo.pausePoint !== undefined) {
      setShowPlayButton(false);
      if (data.currentTime >= currentVideo.pausePoint && !questionAnswered.current) {
        handlePause();
        setIsPaused(true);
        setShowReplayButton(true);
      }
    }
  }, [currentVideo, questionAnswered]);

  // Function to handle replaying the video
  const handleReplay = useCallback(() => {
    if (currentVideo && currentVideo.id) {
      const player = videoRef.current;
      if (player) {
        // Use setPositionAsync to seek to the beginning
        player.setPositionAsync(0).then(() => {
          // Optionally, you can handle any logic after the seek operation here
          player.playAsync(); // Resume playing after seeking
        });
      }
      setShowReplayButton(false);
    }
  }, [currentVideo]);

  const getVideoStyle = useCallback((aspectRatioString: string): ViewStyle => {
    const [widthStr, heightStr] = aspectRatioString.split(':');
    const width = parseInt(widthStr, 10);
    const height = parseInt(heightStr, 10);
    const aspectRatio = width / height;
  
    const isMobileFormat = height > width;
  
    if (!isMobileFormat) {
      // Fullscreen style for non-mobile format videos
      return {
        width: '100%',
        height: '100%',
        alignSelf: 'center' as const,
      };
    } else {
      // Maintain original aspect ratio for mobile format videos
      const videoWidth = Math.min(windowWidth, windowHeight * aspectRatio);
      const videoHeight = videoWidth / aspectRatio;
      return {
        aspectRatio: aspectRatio,
        width: videoWidth,
        height: videoHeight,
        alignSelf: 'center' as const,
      };
    }
  }, [windowWidth, windowHeight]);

  // Adjusted handleOnPlaybackStatusUpdate function
  const handleOnPlaybackStatusUpdate = useCallback((videoId: string) => (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      if (status.isPlaying) {
        // Update progress
        const currentTime = status.positionMillis / 1000;
        onProgress({ currentTime }, videoId);
        fadeOutPlayButton();
      }
      // Add other status handling if needed
    }
  }, [onProgress, fadeOutPlayButton]);

  const renderItem = useCallback(({ item, index }: { item: VideoData, index: number }) => {
    const videoStyle = getVideoStyle(item.aspectRatio || '9:16');

    return (
      <MemoizedVideoItem
        item={item}
        index={index}
        videoStyle={videoStyle}
        videoRef={videoRef}
        isLoading={isLoading}
        showPlayButton={showPlayButton}
        showReplayButton={showReplayButton}
        isCommentsVisible={isCommentsVisible}
        username={username}
        handleReplay={handleReplay}
        handlePlay={handlePlay}
        handleAnswerSelect={handleAnswerSelect}
        handleOnPlaybackStatusUpdate={handleOnPlaybackStatusUpdate}
        updateWatchedVideo={updateWatchedVideo}
        handleCommentsToggle={handleCommentsToggle}
        fadeOutPlayButton={fadeOutPlayButton}
        fadeInPlayButton={fadeInPlayButton}
        playButtonAnim={playButtonAnim}
        setIsPaused={setIsPaused}
        dynamicStyles={dynamicStyles}
        questionAnswered={questionAnswered.current} // Pass the state
      />
    );
  }, [
    getVideoStyle,
    videoRef,
    isLoading,
    showPlayButton,
    showReplayButton,
    isCommentsVisible,
    username,
    handleReplay,
    handlePlay,
    handleAnswerSelect,
    handleOnPlaybackStatusUpdate,
    updateWatchedVideo,
    handleCommentsToggle,
    fadeOutPlayButton,
    fadeInPlayButton,
    playButtonAnim,
    dynamicStyles,
    questionAnswered,
  ]);

  const getItemLayout = useCallback((data: ArrayLike<VideoData> | null | undefined, index: number) => ({
    length: VID_HEIGHT,
    offset: VID_HEIGHT * index,
    index,
  }), []);

  // useEffect to ensure the video plays when the ref is set
  useEffect(() => {
    if (currentVideo && currentVideo.id) {
      if(videoRef.current) {
        videoRef.current.playAsync().catch(err => 
          console.error("Error playing video:", err)
        );
      }
    }
  }, [currentVideo]);

  useEffect(() => {
    const loadVideos = async () => {
      setIsLoading(true);
      
      try {
        const videoDataPromises = videoIds.map(id => fetchVideoData(id));
        const videoData = await Promise.all(videoDataPromises);
        const filteredVideoData = videoData.filter((v): v is VideoData => v !== undefined);
        setVideos(filteredVideoData);
        
        // Set the initial video if necessary
        if (initialVideoId) {
          const initialVideo = filteredVideoData.find(video => video.id === initialVideoId);
          if (initialVideo) {
            setCurrentVideo(initialVideo);
            if(videoRef.current) {
              videoRef.current.playAsync().catch(err => 
                console.error("Error playing initial video:", err)
              );
            }
          }
        }
      } catch (error) {
        console.error('Failed to load videos:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadVideos();
  }, [videoIds, initialVideoId, fetchVideoData]);
  
  useEffect(() => {
    const scrollToSelectedVideo = () => {
      if (initialVideoId && videos.length > 0) {
        const initialIndex = videos.findIndex(video => video.id === initialVideoId);
        
        if (initialIndex !== -1) {
          flatListRef.current?.scrollToIndex({animated: false, index: initialIndex});
        }
      }
    };
  
    if (!isLoading) {
      scrollToSelectedVideo();
    }
  }, [initialVideoId, videos, isLoading]);

  useEffect(() => {
    const player = videoRef.current;
    if(isFocused){
      handlePlay();
    } else {
      handlePause();
    }
  }, [isFocused]);

  // If we're done loading, but have NO videos, show an error
  if (!isLoading && videos.length === 0) {
    return (
      <View style={styles.errorContainer}>
        <Text style={styles.errorText}>Sorry we couldn't load this video.</Text>
        
        <TouchableOpacity
          style={styles.goBackButton}
          onPress={() => navigation.goBack()}
        >
          <FontAwesomeIcon icon={faArrowLeft} size={50} color="#FFF" />
        </TouchableOpacity>
      </View>
    );
  }

  if (isLoading) {
    return <LoadingComponent />;
  }
  
  return (
    <View style={styles.quizContainer}>
      <FlatList
        data={videos}
        ref={flatListRef}
        pagingEnabled={true}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        getItemLayout={getItemLayout}
        onScrollToIndexFailed={scrollToIndexFailed}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

export default QuizViewerProfile;