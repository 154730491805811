// SafeProviders.tsx
import React, { ReactNode } from 'react';
import { 
  SafeAreaProvider as RNSafeAreaProvider, 
  SafeAreaProviderProps as RNSafeAreaProviderProps 
} from 'react-native-safe-area-context';
import { 
  PaperProvider as RNPaperProvider, 
  MD3Theme 
} from 'react-native-paper';

// For SafeAreaProvider
export const SafeAreaProvider: React.FC<RNSafeAreaProviderProps> = (props) => {
  // Just pass all props through, letting React Native handle them
  return <RNSafeAreaProvider {...props} />;
};

// For PaperProvider
type RNPaperProviderProps = React.ComponentProps<typeof RNPaperProvider>;

export const PaperProvider: React.FC<RNPaperProviderProps> = (props) => {
  // Just pass all props through, letting React Native Paper handle them
  return <RNPaperProvider {...props} />;
};