// VideoPlayer.tsx
import React, { forwardRef, useImperativeHandle, useRef, useEffect, useState, useCallback, memo } from 'react';
import { ViewStyle, Dimensions } from 'react-native';
import { Video, ResizeMode, AVPlaybackStatus } from 'expo-av';
import Animated, { 
  useSharedValue, 
  useAnimatedStyle, 
  withTiming, 
  Easing 
} from 'react-native-reanimated';
// Import our emitter
import { emitter, ModalEvent } from '../AnswersModal/slideEvent';
import { StyleSheet } from 'react-native';

type VideoPlayerProps = {
  source: { uri: string };
  style: ViewStyle;
  videoStyle: ViewStyle;
  videoId: string;
  onLoad?: () => void;
  onPlaybackStatusUpdate?: (status: AVPlaybackStatus) => void;
  isLooping?: boolean;
  shouldPlay?: boolean;
  resizeMode?: ResizeMode;
  commentsVisible?: boolean;
};

const VideoPlayer = memo(forwardRef<Video, VideoPlayerProps>((props, ref) => {
  const {
    source,
    style,
    videoStyle,
    onLoad,
    onPlaybackStatusUpdate,
    isLooping = true,
    shouldPlay = true,
    resizeMode = ResizeMode.COVER,
    commentsVisible = false,
    videoId,
  } = props;

  // Add state to track modal visibility and answered state
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAnswered, setModalAnswered] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);
  const [numberAnswers, setNumberAnswers] = useState(0);

  // Create a ref to the Video component that we'll forward
  const videoRef = useRef<Video>(null);

  // Forward the ref to the parent
  useImperativeHandle(ref, () => videoRef.current as Video);

  // Subscribe to modal state changes with useCallback to prevent unnecessary recreations
  const handleModalStateChange = useCallback((event: ModalEvent) => {
    // Only process events for this video
    if (event.videoId === videoId) {
      setModalVisible(event.isVisible);
      setModalAnswered(event.isAnswered);
      setModalHeight(event.modalHeight);
      setNumberAnswers(event.numberAnswers);
    }
  }, [videoId]);

  // Subscribe to modal state changes
  useEffect(() => {
    // Subscribe to modal state changes
    emitter.on('modal-state-change', handleModalStateChange);

    // Clean up subscription
    return () => {
      emitter.off('modal-state-change', handleModalStateChange);
    };
  }, [handleModalStateChange]);

  // #######################################################################
  //                            COMMENTS ANIMATION
  // #######################################################################
  const BOTTOM_TAB_OFFSET = 55;
  // Get window dimensions
  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const videoHeight = windowHeight - 55;
  
  // Calculate video dimensions based on 9:16 aspect ratio
  const aspectRatio = 9/16;
  const originalVideoWidth = Math.min(windowWidth, videoHeight * aspectRatio);
  const originalVideoHeight = videoHeight;
  
  // Calculate top 40% section dimensions
  const targetHeight = windowHeight * 0.4;
  
  // Calculate translation to center in top 40%
  const translateToTop = -(windowHeight * 0.635); // Move up by 30% of window height
  
  // Calculate scale to fit in top 40% while maintaining aspect ratio
  const neededScale = targetHeight / originalVideoHeight;

  // #######################################################################
  //                            ANSWERS ANIMATION
  // #######################################################################
  const ANSWERS_MODAL_PADDING = 17.5;
  const ANSWERS_MODAL_QUESITON_PADDING = 10 * numberAnswers;
  // Calculate modal animation values
  // If a modal is high on the screen, we need to move the video down
  // If a modal is answered and has moved down, we can keep the video in place
  const modalTranslation = -1 * modalHeight + BOTTOM_TAB_OFFSET + ANSWERS_MODAL_PADDING + ANSWERS_MODAL_QUESITON_PADDING;

  const answerModalScale = ( modalTranslation + originalVideoHeight ) / originalVideoHeight;

  // #######################################################################
  //                        ANIMATION CONTROLS
  // #######################################################################
  
  // Shared values for animation
  const scale = useSharedValue(1);
  const translateY = useSharedValue(0);

  // Update scale and position based on comments or modal visibility
  useEffect(() => {
    // Modal takes precedence over comments for animation
    if (modalVisible) {
      // Scale down slightly if modal is showing
      scale.value = withTiming(modalAnswered ? answerModalScale : 1, {
        duration: 300,
        easing: Easing.bezier(0.25, 0.1, 0.25, 1),
      });

      translateY.value = withTiming(modalAnswered ? modalTranslation : 0, {
        duration: 300,
        easing: Easing.bezier(0.25, 0.1, 0.25, 1),
      });
    } 
    // If no modal but comments are visible
    else if (commentsVisible) {
      translateY.value = withTiming(translateToTop, {
        duration: 300,
        easing: Easing.bezier(0.25, 0.1, 0.25, 1),
      });

      scale.value = withTiming(neededScale, {
        duration: 300,
        easing: Easing.bezier(0.25, 0.1, 0.25, 1),
      });
    } 
    // Neither modal nor comments are visible
    else {
      scale.value = withTiming(1, {
        duration: 300,
        easing: Easing.bezier(0.25, 0.1, 0.25, 1),
      });
      
      translateY.value = withTiming(0, {
        duration: 300,
        easing: Easing.bezier(0.25, 0.1, 0.25, 1),
      });
    }
  }, [commentsVisible, modalVisible, modalAnswered, modalHeight, neededScale, translateToTop, modalTranslation, answerModalScale]);

  // Animated style for the wrapper View
  const animatedContainerStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { scale: scale.value },
        { translateY: translateY.value }
      ],
      borderRadius: scale.value < 1 ? 12 : 0,
    };
  });

  // TODO: THIS SHIT PRETTY GOOD BUT THE SIZE GOT ALL FUCKED UP SO CHECK THAT SHIT OUT TOMORROW 

  return (
    <Animated.View 
      style={[
        styles.container, 
        animatedContainerStyle, 
        { 
          width: originalVideoWidth, 
          height: originalVideoHeight 
        }
      ]}
    >
      <Video
        source={source}
        style={[styles.video, style]}
        videoStyle={videoStyle}
        isLooping={isLooping}
        resizeMode={resizeMode}
        shouldPlay={shouldPlay && !modalVisible} // Pause video when modal is visible
        onLoad={onLoad}
        ref={videoRef}
        onPlaybackStatusUpdate={onPlaybackStatusUpdate}
      />
    </Animated.View>
  );
}), (prevProps, nextProps) => {
  // Custom comparison function for React.memo
  // Only re-render when these specific props change
  return (
    prevProps.videoId === nextProps.videoId &&
    prevProps.source.uri === nextProps.source.uri &&
    prevProps.commentsVisible === nextProps.commentsVisible &&
    prevProps.shouldPlay === nextProps.shouldPlay
    // We intentionally don't compare style and videoStyle - if structure changes but values are the same, a re-render isn't needed
    // We don't compare isLooping, onLoad, etc. since they rarely change
  );
});

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    overflow: 'hidden', // Ensure video doesn't overflow when rounded
  },
  video: {
    width: '100%',
    height: '100%',
  }
});

export default VideoPlayer;