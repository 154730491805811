// AuthContext.tsx
import React, { createContext, useState, FC, ReactNode, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { WatchedVideoData } from '../components/Quiz/QuizTypes';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";

import { User } from '../hooks/user/UserTypes';
import { API_URL } from '@env';
import axios from 'axios';
import { Audio } from 'expo-av'; // Import expo-av for audio playback

type firebaseUser = {
  uid: string;
  email: string;
  displayName: string;
  // Add any other user profile fields you expect
};

type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  userProfile: User | null;
  setUserProfile: (profile: User) => void;
  updateUserFollowing: (userIds: string[]) => void;
  updateUserFollowingCount: (count: number) => void;
  updateWatchedVideos: (videoDatum: WatchedVideoData[]) => void;
  updateBlockedUsers: (userIds: string[]) => void;
  getToken: () => Promise<string | null>; // Add this line
  getUserProfile: () => Promise<User>; // Add this line
};


const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  userProfile: null, // Add this line
  setUserProfile: () => {}, // Add this line
  updateUserFollowing: () => {},
  updateUserFollowingCount: () => {},
  updateWatchedVideos: () => {},
  updateBlockedUsers: () => {},
  getToken: async () => null, // Add this line
  getUserProfile: async () => ({} as User), // Add this line
});

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [userProfile, setUserProfile] = useState<User | null>(null); // Add this line

  // Function to update followingUserIds in the userProfile
  const updateUserFollowing = (userIds: string[]) => {
    if (userProfile) {
      setUserProfile({ ...userProfile, followingUserIds: userIds });
    }
  };

  const updateUserFollowingCount = (count: number) => {
    if (userProfile) {
      setUserProfile({ ...userProfile, followingCount: count });
    }
  }

  useEffect(() => {
    async function checkSession() {
      try {

        const userProfileString = await AsyncStorage.getItem('userProfile');

        if (userProfileString) {
          const userProfile: User = JSON.parse(userProfileString);

          const response = await axios.get(`${API_URL}/users/auth/${userProfile?.username}`, 
            { withCredentials: true }
          );

          if (response.data && response.data.user) {

            const userProfileResponse = response.data.user;

            const userData : User = {
              username: userProfileResponse.username,
              bio: userProfileResponse.bio,
              watchedVideos: userProfileResponse.watchedVideos,
              profilePicture: userProfileResponse.profilePicture,
              blockedUserIds: userProfileResponse.blockedUserIds,    
              correctSoundUrl: userProfileResponse.correctSound,
              incorrectSoundUrl: userProfileResponse.incorrectSound,
              followersCount: userProfileResponse.followersCount,
              followingCount: userProfileResponse.followingCount,
              followersUserIds: userProfileResponse.followersUserIds,
              followingUserIds: userProfileResponse.followingUserIds,
              uploadedVideos: userProfileResponse.uploadedVideos,
          };

            await AsyncStorage.setItem('userProfile', JSON.stringify(userData));
            setUserProfile(userData as User);
            setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      }
      } catch (error) {
        // 401 or other error means not logged in
        setIsAuthenticated(false);
      }
    }
    checkSession();
  }, []);

  const getToken = () => {
    return new Promise<string | null>((resolve, reject) => {
      const auth = getAuth(); // Get the Firebase Auth instance
      // Setting up a one-time listener to auth state change
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        unsubscribe(); // Unsubscribe after getting the user state
  
        if (!user) {
          
          resolve(null); // Resolve the promise with null if no user is signed in
          return;
        }
        
        const newIdToken = await getIdToken(user, true); // Force token refresh
        resolve(newIdToken); // Resolve the promise with the new token
      }, reject); // In case of an error, reject the promise
    });
  };
  

  const updateWatchedVideos = (videoDatum: WatchedVideoData[]) => {
    setUserProfile((prevProfile) => {
      if (prevProfile) {
        return { ...prevProfile, watchedVideos: videoDatum };
      }
      return prevProfile; // In case prevProfile is null, return it as is.
    });
  };

  // ...inside AuthProvider
  const updateBlockedUsers = (userIds: string[]) => {
    if (userProfile) {
      setUserProfile({ ...userProfile, blockedUserIds: userIds }); // Use the correct property name
    }
  };  

  const getUserProfile = async () => {
    
    try{
      if( userProfile && userProfile.username ){
        return userProfile;
      }
      else
      {
        const userProfileAsyncStorage = await AsyncStorage.getItem('userProfile');
        if (userProfileAsyncStorage) {
          const userProfileFromAsync: User = JSON.parse(userProfileAsyncStorage);
          return userProfileFromAsync;
        }
      }
      return {} as User;
    } catch (error) {
      console.error("Error fetching userProfile from AsyncStorage:", error);
      return {} as User;
    }
  };

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      setIsAuthenticated,
      userProfile, // Add this line
      setUserProfile, // Add this line
      updateUserFollowing,
      updateUserFollowingCount,
      updateWatchedVideos,
      updateBlockedUsers,
      getToken,
      getUserProfile,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;
