import { createStackNavigator } from '@react-navigation/stack';
import { UserViewOther, QuizViewerProfile, UserView, SubmitClip, QuestionForm } from './LazyNavigatorComponents'
import { StackParamList } from './navigationTypes';
import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import React from 'react';
import { View } from '../context/SafeComponents';

// Export a ref to control this child navigator
export const submissionStackRef = React.createRef<NavigationContainerRef<any>>(); 

function SubmitClipNavigator() {

  const SubmitClipStack = createStackNavigator<StackParamList>();

  return (
    <View style={{height: '100%', backgroundColor: 'black'}}>
      <NavigationContainer
        independent={true}
        ref={submissionStackRef}
      >
        <SubmitClipStack.Navigator screenOptions={{headerShown: false}} initialRouteName="SubmitClip">
          <SubmitClipStack.Screen name="SubmitClip" component={SubmitClip} />
          <SubmitClipStack.Screen name="QuestionForm" component={QuestionForm} />
          <SubmitClipStack.Screen name="QuizViewerProfile" component={QuizViewerProfile}/>
          <SubmitClipStack.Screen name="UserViewOther" component={UserViewOther} />
          <SubmitClipStack.Screen name="UserView" component={UserView} />
          {/* Add other screens here if necessary */}
        </SubmitClipStack.Navigator>
      </NavigationContainer>
    </View>
  );
}

export default SubmitClipNavigator;
