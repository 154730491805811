import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: 'black'
  },
  sectionTitle: {
    color: 'white',
    fontSize: 16,
    fontFamily: 'PressStart2P_400Regular',
    fontWeight: 'bold',
    padding: 10,
  },
  searchBar: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  input: {
    flex: 1,
    borderColor: 'gray',
    borderWidth: 1,
    marginRight: 10,
    padding: 5,
    color: 'white'
  },
  userItem: {
    padding: 5,
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    flexDirection: 'row',
  },
  userText: {
    color: 'white',
    fontFamily: 'PressStart2P_400Regular',
    flex: 2,
    top: 8,
  },
  commentAvatar: {
    width: 32, // smaller size for the thumbnail
    maxWidth: 32,
    height: 32,
    flex: 1,
    borderRadius: 16,
    marginRight: 8, // Add some spacing between the avatar and text
  }
});

export default styles;
