// UserSearchNavigator.tsx
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import UserViewOther from '../components/UserViewOther/UserViewOther';
import QuizViewerProfile from '../components/QuizViewerProfile/QuizViewerProfile';
import UserView from '../components/UserView/UserView';
import UserSearch from '../components/UserSearch/UserSearch';
import UserViewSelf from '../components/UserViewSelf/UserViewSelf';
import { StackParamList } from './navigationTypes';
import { View } from '../context/SafeComponents';
import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native';

// Export a ref to control this child navigator
export const userSearchStackRef = React.createRef<NavigationContainerRef<any>>(); 

function UserSearchNavigator() {

  const Stack = createStackNavigator<StackParamList>();

  return (
    <View style={{height: '100%', backgroundColor:'black'}}>

      <NavigationContainer
        independent={true}
        ref={userSearchStackRef}
      >
        <Stack.Navigator screenOptions={{headerShown: false}} initialRouteName="UserSearch">
          <Stack.Screen name="UserSearch" component={UserSearch} />
          <Stack.Screen name="UserView" component={UserView} />
          <Stack.Screen name="UserViewOther" component={UserViewOther} />
          <Stack.Screen name="UserViewSelf" component={UserViewSelf} />
          <Stack.Screen name="QuizViewerProfile" component={QuizViewerProfile} />
          {/* ... other screens ... */}
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  );
}

export default UserSearchNavigator;