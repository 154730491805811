import React, { useState } from 'react';
import {
  Modal,
  View,
  Text,
  StyleSheet,
  Pressable,
  ScrollView,
  
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import axios from 'axios';
import { StackNavigationProp } from '@react-navigation/stack';
import { StackParamList } from '../../navigators/navigationTypes';
import Image from '../UtilityComponents/UniversalImage';
import styles from './style';

interface UsersListModalProps {
    users: string[] | null | undefined; // Now expects an array of strings (usernames)
    isVisible: boolean;
    toggleModal: () => void;
}

import { API_URL } from '@env';

const UserItem: React.FC<{ username: string; navigateToUserViewOther: (username: string) => void }> = ({ username, navigateToUserViewOther }) => {
    
    const [imageUri, setImageUri] = useState<string>(`https://storage.googleapis.com/clipdle-profile-pics/${username}_thumbnail.jpeg`);
    
    return (
    <View style={styles.userContainer}>
        <Pressable onPress={() => navigateToUserViewOther(username)}>
        <Image
            source={{ uri: imageUri }}
            style={styles.userAvatar}
            onError={() => setImageUri('https://storage.googleapis.com/clipdle-profile-pics/Default_pfp.jpeg')}
        />
        </Pressable>
        <Text style={styles.usernameText}>{username}</Text>
    </View>
    );
};

const UsersListModal: React.FC<UsersListModalProps> = ({ users, isVisible, toggleModal }) => {
    const navigation = useNavigation<StackNavigationProp<StackParamList>>();

    const navigateToUserViewOther = async (username: string) => {
        try {
            const response = await axios.get(`${API_URL}/users/find/${username}`,
              {
                withCredentials: true,
              }
            );
            const userProfile = response.data;

            // Correct way to navigate
            navigation.navigate('UserView', {
              username: userProfile.username,
            });

            toggleModal();
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    };
  
    return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={toggleModal}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <ScrollView>
            {users?.map((user, index) => (
              <UserItem key={index} username={user} navigateToUserViewOther={navigateToUserViewOther} />
            ))}
          </ScrollView>

          <Pressable
            style={styles.closeButton}
            onPress={toggleModal}
          >
            <Text style={styles.closeButtonText}>Close</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

export default UsersListModal;
