// SafeComponents.tsx - Revised
import React from 'react';
import { 
  View as RNView, 
  Image as RNImage, 
  Text as RNText,
  ViewProps,
  ImageProps,
  TextProps,
  StyleProp,
  ViewStyle,
  ImageStyle
} from 'react-native';

// For View
interface SafeViewProps extends ViewProps {
  style?: StyleProp<ViewStyle>;
}

// For Image
interface SafeImageProps extends ImageProps {
  style?: StyleProp<ImageStyle>;
}

// Safe View component that ensures pointerEvents is used as a prop, not in style
export const View = React.forwardRef<RNView, SafeViewProps>(
  ({ style, ...rest }, ref) => {
    let safeStyle = style;
    let safeRest = {...rest};
    
    // If pointerEvents is in style, move it to props
    if (style && typeof style === 'object' && 'pointerEvents' in style) {
      const { pointerEvents, ...restStyle } = style as any;
      safeStyle = restStyle;
      safeRest.pointerEvents = pointerEvents;
    }
    
    return <RNView ref={ref} style={safeStyle} {...safeRest} />;
  }
);

// Safe Image component that ensures resizeMode is used as a prop, not in style
export const Image = React.forwardRef<RNImage, SafeImageProps>(
  ({ style, ...rest }, ref) => {
    let safeStyle = style;
    let safeRest = {...rest};
    
    // If resizeMode is in style, move it to props
    if (style && typeof style === 'object' && 'resizeMode' in style) {
      const { resizeMode, ...restStyle } = style as any;
      safeStyle = restStyle;
      safeRest.resizeMode = resizeMode;
    }
    
    return <RNImage ref={ref} style={safeStyle} {...safeRest} />;
  }
);

// Export other components as needed
export const Text = RNText;