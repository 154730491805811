// RefetchContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

type RefetchContextType = {
  refetchTrigger: number;
  setRefetchTrigger: React.Dispatch<React.SetStateAction<number>>;
};

const RefetchContext = createContext<RefetchContextType | undefined>(undefined);

// Update the provider to simply ignore any pointerEvents or style props
export const RefetchProvider: React.FC<{ 
  children: ReactNode;
  pointerEvents?: string; // Accept but ignore this prop
  style?: any; // Accept but ignore this prop
  [key: string]: any; // Accept any other props
}> = ({ 
  children,
  pointerEvents, // Explicitly ignored
  style, // Explicitly ignored
  ...otherProps // Any other safe props to pass through
}) => {
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  
  // Only pass the value prop and children to the Provider
  return (
    <RefetchContext.Provider value={{ refetchTrigger, setRefetchTrigger }}>
      {children}
    </RefetchContext.Provider>
  );
};

export const useRefetch = (): RefetchContextType => {
  const context = useContext(RefetchContext);
  if (context === undefined) {
    throw new Error('useRefetch must be used within a RefetchProvider');
  }
  return context;
};