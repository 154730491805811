import React, { useState } from 'react';
import { ScrollView, Dimensions } from 'react-native';
import { View } from '../../context/SafeComponents';
import { Button, Dialog, Portal, Text } from 'react-native-paper';
import CustomButton from '../CustomButton/CustomButton';

type InfoModalProps = {
  visible: boolean;
  hideModal: () => void;
};

// Define a type for the valid keys of ContentDetails
type ContentKey = 'PrivacyPolicy' | 'TermsOfService' | 'CookiePolicy' | 'GDPRCompliance' | 'CCPACompliance';

const InfoModal: React.FC<InfoModalProps> = ({ visible, hideModal }) => {
    const [contentToShow, setContentToShow] = useState<ContentKey | ''>('');
  
    const ContentDetails: Record<ContentKey, string[] | string> = {
      PrivacyPolicy: [
        "Introduction: At Clipdle, we respect your privacy and are committed to protecting it through our compliance with this policy.",
        "Information We Collect:",
        "• Email address",
        "• Name",
        "• Profile picture URL",
        "• Bio and the date of sign-up",
        "• Videos watched, liked, and uploaded",
        "Use of Your Information: We use the information we collect about you to provide, maintain, and improve our services, and to communicate with you.",
      ],
      TermsOfService: [
        "Welcome to Clipdle ('we', 'us', 'our'). By accessing or using our website and services (collectively, the 'Service'), you agree to be bound by these Terms of Service and our Privacy Policy. If you do not agree to these terms, please do not use our Service.",
        "1. Acceptable Use Policy: You are responsible for your use of the Service and for any content you post, including compliance with applicable laws, rules, and regulations. You should only provide content that you are comfortable sharing with others under these Terms.",
        "a. Illegal Activities: You may not use our Service for any unlawful purposes or in furtherance of illegal activities. By using the Service, you agree not to violate any law, contract, intellectual property, or other third-party right.",
        "b. Harassment: You may not use our Service to engage in harassment, threats, stalking, spamming, or other unwanted conduct toward others.",
        "2. Content Standards: You agree not to post any content that:",
        "- Is illegal, or promotes illegal activities or conduct.",
        "- Is threatening, harassing, defamatory, invasive of another's privacy, or abusive.",
        "- Contains falsehoods or misrepresentations that could damage us or any third party.",
        "- Impersonates any person, or misrepresents your affiliation with a person or entity.",
        "- Contains or links to viruses, corrupted data, or other harmful, disruptive, or destructive files.",
        "3. User Contributions: The Service may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, and other interactive features that allow users to post, submit, publish, display, or transmit content to other users or other persons on or through the Service.",
        "Any content you post to the site will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but you are required to grant us and other users of the Service certain rights to use, copy, distribute, display, and perform your content.",
        "4. Enforcement: We have the right to:",
        "- Remove or refuse to post any user contributions for any reason in our sole discretion.",
        "- Take any action with respect to any user contribution that we deem necessary or appropriate if we believe that such contribution violates these Terms of Service, infringes any intellectual property right, or could pose a liability for us.",
        "- Disclose your identity to any third party who claims that your content violates their rights, including their intellectual property rights or their right to privacy.",
        "- Take appropriate legal action, including referral to law enforcement, for any illegal or unauthorized use of the Service.",
        "5. Termination: We may terminate or suspend your access to all or part of the Service for any reason, including without limitation, any violation of these Terms of Service.",
        "6. Governing Law: These terms shall be governed by and construed in accordance with the laws of your Country and State laws, without regard to its conflict of law provisions.",
        "7. Changes to Terms of Service: We may revise these Terms of Service at any time without notice. By using the Service you are agreeing to be bound by the then-current version of these Terms of Service.",
        "8. Contact Information: Questions about the Terms of Service should be sent to us at brendan@clipdle.tv.",
        "Thank you for choosing to be part of our community at Clipdle.",
      ],
      CookiePolicy: [
        "Cookie Policy",
        "Last updated: 2/25/2024",
        "We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.",
        "Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device.",
        "Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.",
        "You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.",
        "Examples of Cookies we use:",
        "- Session Cookies: We use Session Cookies to operate our Service.",
        "- Preference Cookies: We use Preference Cookies to remember your preferences and various settings.",
        "- Security Cookies: We use Security Cookies for security purposes.",
        "- Advertising Cookies: Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.",
        "If you have any questions about our Cookie Policy, please contact us at brendan@clipdle.tv.",
      ],
      GDPRCompliance: [
        "GDPR Compliance",
        "Effective date: 2/25/2024",
        "In compliance with the General Data Protection Regulation (GDPR), we provide the following rights to our European Union (EU) users:",
        "- The right to be informed: You have the right to be informed about the collection and use of your personal data.",
        "- The right of access: You can request a copy of your personal data that we hold.",
        "- The right to rectification: If your personal data is inaccurate or incomplete, you are entitled to have it corrected.",
        "- The right to erasure: You can request the deletion or removal of your personal data when there is no compelling reason for its continued processing.",
        "- The right to restrict processing: You have the right to 'block' or suppress further use of your data under certain conditions.",
        "- The right to data portability: You have the rights to obtain and reuse your personal data for your own purposes across different services.",
        "- The right to object: You have the right to object to the processing of your personal data in certain circumstances, including processing for direct marketing.",
        "We have implemented data protection practices to ensure that your personal data is processed with the utmost care and consideration. This includes, but is not limited to:",
        "- Using secure servers to store your personal information.",
        "- Employing proper data encryption.",
        "- Limiting the access to your personal information to authorized personnel only.",
        "If you wish to exercise any of the rights set out above, please contact us at brendan@clipdle.tv.",
      ],
      
      CCPACompliance: [
        "CCPA Compliance",
        "Effective date: 2/25/2024",
        "In accordance with the California Consumer Privacy Act (CCPA), we afford California residents certain rights regarding their personal information. The CCPA provides California residents with the following rights:",
        "- The right to know what personal information is being collected about them.",
        "- The right to know whether their personal information is sold or disclosed and to whom.",
        "- The right to say no to the sale of personal information (the right to opt-out).",
        "- The right to access their personal information.",
        "- The right to equal service and price, even if they exercise their privacy rights under CCPA.",
        "- The right to request deletion of their personal information.",
        "We have implemented measures to ensure compliance with the CCPA, including but not limited to:",
        "- Providing clear and accessible information on our website about the types of personal information we collect and the purposes for which we use it.",
        "- Maintaining a data inventory to keep track of the movement of personal information within and outside the organization.",
        "- Creating processes to respond to legal requests by California residents to access, delete, or opt-out of the sale of their personal information within the timeframe required by the CCPA.",
        "- Ensuring that all employees handling California resident information are trained on the CCPA and how to guide consumers in exercising their rights.",
        "If you wish to exercise any of the rights described above, please submit a verifiable consumer request to us by contacting us at brendan@clipdle.tv.",
        "Please note that we will not discriminate against you for exercising any of your CCPA rights.",
        "If you have any questions or concerns about our CCPA Compliance, please contact us at brendan@clipdle.tv.",
      ],
    };
  
    const handleContentChange = (section: ContentKey) => {
      setContentToShow(section);
    };

    const renderMenuButtons = () => {
        return !contentToShow && (
          <>
            <CustomButton buttonStyle={{fontFamily: 'PressStart2P_400Regular'}} onPress={() => handleContentChange('PrivacyPolicy')} title="Privacy Policy"/>
            <CustomButton buttonStyle={{fontFamily: 'PressStart2P_400Regular'}} onPress={() => handleContentChange('TermsOfService')} title="Terms of Service"/>
            <CustomButton buttonStyle={{fontFamily: 'PressStart2P_400Regular'}} onPress={() => handleContentChange('CookiePolicy')} title="Cookie Policy"/>
            <CustomButton buttonStyle={{fontFamily: 'PressStart2P_400Regular'}} onPress={() => handleContentChange('GDPRCompliance')} title="GDPR Compliance"/>
            <CustomButton buttonStyle={{fontFamily: 'PressStart2P_400Regular'}} onPress={() => handleContentChange('CCPACompliance')} title="CCPA Compliance"/>
          </>
        );
      };
  
    const renderBulletPoints = (points: string[]) => (
      <View>
        {points.map((point, index) => (
          <Text key={index} style={{ marginBottom: 5, color: 'white', fontFamily: 'PressStart2P_400Regular'}}>
            {point}
          </Text>
        ))}
      </View>
    );
  
    const renderContent = () => {
      // Safely access ContentDetails using contentToShow
      if (contentToShow) {
        const content = ContentDetails[contentToShow];
        if (Array.isArray(content)) {
          return renderBulletPoints(content);
        } else {
          return <Text style={{color: 'white', fontFamily: 'PressStart2P_400Regular'}}>{content}</Text>;
        }
      }
      // Return null or a default view if contentToShow is not set
      return null;
    };

    // Use window dimensions to dynamically adjust the modal height
    const windowHeight = Dimensions.get('window').height;
  
    return (
        <Portal>
          <Dialog
            visible={visible}
            onDismiss={() => { hideModal(); setContentToShow(''); }}
            style={{ width: '80%', alignSelf: 'center', maxHeight: windowHeight * 0.8 }}
          >
            <Dialog.Title style={{color: 'white', fontFamily: 'PressStart2P_400Regular'}}>Website Info</Dialog.Title>
            <Dialog.ScrollArea style={{ maxHeight: windowHeight * 0.7 }}>
              <ScrollView>
                {renderContent() || renderMenuButtons()}
              </ScrollView>
            </Dialog.ScrollArea>
            <Dialog.Actions>
              {contentToShow ? <CustomButton onPress={() => setContentToShow('')} title="Back"/> : null}
              <CustomButton onPress={() => { hideModal(); setContentToShow(''); }} title="Close"/>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      );
  };
  
  export default InfoModal;