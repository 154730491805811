import React, { useState } from 'react';
import { FlatList, Pressable, StyleSheet, Dimensions } from 'react-native';
import { Image } from '../../context/SafeComponents';

type VideoGalleryProps = {
  videos: string[] | null | undefined;
  onVideoPress: (videoId: string) => void;
  onRefresh: () => void;
  refreshing: boolean;
};

type VideoThumbnailProps = {
  videoId: string;
  onPress: (videoId: string) => void;
};

const VideoThumbnail: React.FC<VideoThumbnailProps> = ({ videoId, onPress }) => {
  const [isHovered, setIsHovered] = useState(false);
  const thumbnailUri = `https://storage.googleapis.com/clipdle_videos_thumbnails/${videoId}_thumbnail.jpeg`;
  
  // Calculate dynamic width for each thumbnail.
  const numColumns = 3;
  const margin = 8;
  const windowWidth = Dimensions.get('window').width;
  // Each thumbnail has horizontal margins on both sides.
  const totalMargin = margin * 2 * numColumns;
  // Calculate available width and cap the thumbnail width at 200 pixels.
  const thumbnailWidth = Math.min((windowWidth - totalMargin) / numColumns, 200);

  return (
    <Pressable
      onPress={() => onPress(videoId)}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      style={({ pressed }) => [
        styles.thumbnailContainer,
        (isHovered || pressed) && styles.thumbnailHovered,
      ]}
    >
      <Image
        source={{ uri: thumbnailUri }}
        defaultSource={require('../../assets/images/Ex1.jpg')}
        style={[styles.thumbnailImage, { width: thumbnailWidth, height: thumbnailWidth }]}
      />
    </Pressable>
  );
};

const VideoGallery: React.FC<VideoGalleryProps> = ({ videos, onVideoPress, onRefresh, refreshing }) => {
  return (
    <FlatList
      data={videos}
      keyExtractor={(item) => item}
      renderItem={({ item }) => <VideoThumbnail videoId={item} onPress={onVideoPress} />}
      numColumns={3}
      contentContainerStyle={styles.flatListContainer}
      onRefresh={onRefresh}
      refreshing={refreshing}
    />
  );
};

const styles = StyleSheet.create({
  flatListContainer: {
    paddingTop: 20,
    alignItems: 'center',
  },
  thumbnailContainer: {
    margin: 8,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: '#fff',
    // iOS shadow
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    // Android shadow
    elevation: 2,
  },
  thumbnailHovered: {
    transform: [{ scale: 1.05 }],
    shadowOpacity: 0.5,
    elevation: 4,
  },
  thumbnailImage: {
    aspectRatio: 1,
    resizeMode: 'cover',
  },
});

export default VideoGallery;
