
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext'; // Adjust the path as necessary
import axios from 'axios';

import { API_URL } from '@env';
import { Alert, Platform } from 'react-native';

type CustomImagePickerResponse = {
  didCancel?: boolean;
  errorMessage?: string;
  assets?: Array<{
    uri: string;
    fileName?: string;
    type?: string;
  }>;
};

type CustomLaunchImageLibraryOptions = {
  mediaType: 'photo' | 'video';
  maxHeight?: number;
  maxWidth?: number;
};

let ImagePicker: {
  MediaType: any;
  launchImageLibrary: (options: CustomLaunchImageLibraryOptions, callback: (response: CustomImagePickerResponse) => void) => void;
};

if (Platform.OS !== 'web') {
  ImagePicker = require('react-native-image-picker');
} else {
  ImagePicker = {
    MediaType: undefined,
    launchImageLibrary: () => {
      console.warn('Image picker not available on web');
    }
  };
}

/**
 * Custom hook for handling user authentication.
 * Provides functions for login, fetching user data, and managing custom alerts.
 * @returns An object containing the loading state, login handler, custom alert functions, and alert state.
 */
export const useGetUserInfo = () => {
    const [loading, setLoading] = useState(false);
    const { setUserProfile, userProfile } = useAuth();

    // States for managing CustomAlert
    const [editBioMode, setEditBioMode] = useState(false);
    const [following, setFollowing] = useState<string[]>([]); // Array of strings
    const [followers, setFollowers] = useState<string[]>([]); // Array of strings
    const [followersCount, setFollowersCount] = useState(0); // State for followers count
    const [followingCount, setFollowingCount] = useState(0); // State for following count
    const [didUpload, setDidUpload] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [uploadedVideos, setUploadedVideos] = useState<string[]>([]);

    const [profilePicture, setProfilePicture] = useState(require('../../assets/images/avatar.jpg')); // Default image

    const [editMode, setEditMode] = useState(false);
    const [newBio, setNewBio] = useState(userProfile?.bio ?? '');

    const fetchCountsAndLists = async () => {
        try {

        if ( userProfile?.followersCount != null && userProfile?.followersCount != undefined )
        {
            setFollowersCount(userProfile.followersCount);
        }
        else
        {
            // Fetch followers and following counts
            const followersCountResponse = await axios.get(
            `${API_URL}/users/${userProfile?.username}/followersCount`,
            {
                withCredentials: true,
            }
            );

            setFollowersCount(followersCountResponse.data.followers);
        }

        if ( userProfile?.followingCount != null && userProfile?.followingCount != undefined )
        {
            setFollowingCount(userProfile.followingCount);
        }
        else
        {
            const followingCountResponse = await axios.get(
            `${API_URL}/users/${userProfile?.username}/followingCount`,
            {
                withCredentials: true,
            }
            );
    
            setFollowingCount(followingCountResponse.data.following);
        }

        if ( userProfile?.followersUserIds )
        {
            setFollowers(userProfile.followersUserIds);
        }
        else{
            // Fetch followers and following lists
            const followersResponse = await axios.get(
            `${API_URL}/users/${userProfile?.username}/followers`, 
            {
            withCredentials: true,
            });
            setFollowers(followersResponse.data.followers);
        }

        if ( userProfile?.followingUserIds )
        {
            setFollowing(userProfile.followingUserIds);
        }
        else
        {
            const followingResponse = await axios.get(`${API_URL}/users/${userProfile?.username}/following`,
            {
                withCredentials: true,
            }
            );
            
            setFollowing(followingResponse.data.following);
        }

        } catch (error) {
        console.error('Error fetching counts and lists:', error);
        }
    };

    // Ensure fetchUploadedVideos is accessible in the scope where you need it
    const fetchUploadedVideos = async () => {
        setIsRefreshing(true); // Start refreshing
        try {
        if ( userProfile?.uploadedVideos )
        {
            setUploadedVideos(userProfile.uploadedVideos);
        }
        else
        {
            const response = await axios.get(
            `${API_URL}/users/${userProfile?.username}/uploadedVideos`, 
            {
                withCredentials: true,
            }
            );
            setUploadedVideos(response.data.uploadedVideos);
        }
        } catch (error) {
        console.error('Error fetching uploaded videos:', error);
        } finally {
        setIsRefreshing(false); // Stop refreshing
        }
    };

    const setProfilePictureImage = async () => {
        try {
    
          // If we've cached the profile picture, use it
          if ( userProfile?.profilePicture )
          {
            setLoading(true); // Start loading
    
            setProfilePicture({
              uri: userProfile.profilePicture + '?cacheBust=' + Date.now(),
            });
    
            setEditMode(false);
    
            return;
          }
          else
          {
            setLoading(true); // Start loading
            const response = await axios.get(`${API_URL}/users/${userProfile?.username}/profile-picture`,
              {
                withCredentials: true,
              }
            );
            
            setProfilePicture({
              uri: response.data.profilePictureUrl + '?cacheBust=' + Date.now(),
            });
    
            setEditMode(false);
          }
        } catch (error) {
          console.error('Error fetching profile picture:', error);
          // If error, keep the default profile picture
        } finally {
          setLoading(false); // Stop loading
        }
      };

      const updateBio = async () => {
        // Validate the bio
        if (newBio.length > 200) {
          Alert.alert('Error', 'Bio must be less than 200 characters.');
          return;
        }
    
        try {
          setEditBioMode(false);
    
          await axios.post(
            `${API_URL}/users/${userProfile?.username}/bio`,
            { bio: newBio },
            {
              withCredentials: true,
            }
          );
          Alert.alert('Bio Updated', 'Your bio has been updated successfully.');
    
          setEditMode(false); // Exit edit mode
          // Update local user profile state if needed
          if(userProfile){
            setUserProfile({ ...userProfile, bio: newBio });
          }
        } catch (error) {
          Alert.alert('Error', 'There was an issue updating your bio.');
          console.error('Error updating bio:', error);
        }
      };
    
      
    const pickImageAndUpload = () => {
      if (Platform.OS === 'web') {
        // Web-specific code
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*'; // Accepts all image types
        input.onchange = async (event) => {
          const target = event.target as HTMLInputElement;
          const file = target.files ? target.files[0] : null;
          if (file) {
            const formData  = new FormData();
            formData .append('file', file, file.name);
    
            try {
              const uploadResponse = await axios.post(
                `${API_URL}/users/${userProfile?.username}/profile-picture`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  withCredentials: true,
                }
              );
              const uploadedImageUrl = uploadResponse.data;
    
              setDidUpload(!didUpload); // Toggle the didUpload state to trigger a re-render
            } catch (error: unknown) {
              if (axios.isAxiosError(error)) {
                console.error('Axios error:', error.message);
                if (error.response) {
                  console.error('Response data:', error.response.data);
                  console.error('Response status:', error.response.status);
                  console.error('Response headers:', error.response.headers);
                }
              } else {
                console.error('Non-Axios error:', error);
              }
            }
          }
        };
        input.click();
      } else {
        const options = {
          mediaType: ImagePicker.MediaType.Photo,
          maxHeight: 1000,
        };
    
        ImagePicker.launchImageLibrary(options, async (response) => {
          if (response.errorMessage) {
            console.error('ImagePicker Error: ', response.errorMessage);
          } else if (response.assets && response.assets[0].uri) {
            const asset = response.assets[0];
    
            const fileName = asset.fileName ?? 'unknown';
            const fileUri = asset.uri;
    
            try {
              const responseBlob = await fetch(fileUri);
              const blob = await responseBlob.blob();
    
              const data = new FormData();
              data.append('file', blob, fileName);
    
              await axios.post(
                `${API_URL}/users/${userProfile?.username}/profile-picture`,
                data,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  withCredentials: true,
                }
              );

              setDidUpload(!didUpload); // Toggle the didUpload state to trigger a re-render
            } catch (error: unknown) {
              if (axios.isAxiosError(error)) {
                console.error('Axios error:', error.message);
                if (error.response) {
                  console.error('Response data:', error.response.data);
                  console.error('Response status:', error.response.status);
                  console.error('Response headers:', error.response.headers);
                }
              } else {
                console.error('Non-Axios error:', error);
              }
            }
          } else {
            console.error('No image selected');
          }
        });
      }
    };

  

    return {
        // Login state and functions
        loading,
        following,
        followers,
        followersCount,
        followingCount,
        
        isRefreshing,
        uploadedVideos,

        profilePicture,

        editMode,

        newBio,
        setNewBio,
        editBioMode,
        setEditBioMode,

        didUpload,
        
        fetchUploadedVideos,
        fetchCountsAndLists,
        setProfilePictureImage,
        setEditMode,
        pickImageAndUpload,
        updateBio,
        //...
    };
};