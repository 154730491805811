import React, { useState, useRef, useEffect, useCallback, Suspense } from 'react';
import {
  View,
  Text,
  Animated,
  Alert,
  Pressable
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faThumbsUp } from '../../optimizations/OptimizedIcons';
import { faThumbsDown } from '../../optimizations/OptimizedIcons';
import { faCommentDots } from '../../optimizations/OptimizedIcons';
import { faShareSquare } from '../../optimizations/OptimizedIcons';
import { faTrash } from '../../optimizations/OptimizedIcons';
import { faArrowAltCircleLeft } from '../../optimizations/OptimizedIcons';
import { faFlag } from '../../optimizations/OptimizedIcons';
import Comments from '../Comments/Comments';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { useFocusEffect } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { StackParamList } from '../../navigators/navigationTypes';
import ReportModal from '../ReportModal/ReportModal';
import Image from '../UtilityComponents/UniversalImage';
import CustomAlert from '../Hooks/Alert/Alert';
import { useRefetch } from '../../context/RefetchContext';
const AnswersModal = React.lazy(() => import('../AnswersModal/AnswersModal'));
import styles from './style';
import { WatchedVideoData } from '../Quiz/QuizTypes';
import { useCustomAlert } from '../../hooks/useCustomAlert';
import { API_URL } from '@env';
import AnimatedHoverableButton from '../AnimatedHoverableButton/AnimatedHoverableButton';

type QuizOverlayProps = {
  onAnswerSelect: (answer: string, index: number, isCorrect: boolean) => void;
  correctAnswer: number | null;
  question: string | null;
  answers: string[];
  mongoId: string | null;
  GCSId: string | null;
  isBlocked: boolean | null;
  uploaderUsername: string | null;
  isFromProfile: boolean | null;
  answersModalVisible: boolean | null;
  setAnswersModalVisible: (visible: boolean) => void;
  onVideoInteraction: (updates: Partial<WatchedVideoData>) => void;
  onCommentsToggle?: (open: boolean) => void;
  onAnswersModalStateChange?: (isAnswered: boolean, modalHeight: number) => void;
};

const QuizOverlay: React.FC<QuizOverlayProps> = ({
  mongoId,
  GCSId,
  onAnswerSelect,
  correctAnswer,
  question,
  isBlocked,
  answers,
  uploaderUsername,
  isFromProfile,
  onVideoInteraction,
  onCommentsToggle,
  onAnswersModalStateChange = (isAnswered, modalHeight) => {
    // Default implementation that just logs
  },
}) => {

  // Add this debugging log at component initialization
  
  // You can safely use onAnswersModalStateChange now with this fallback
  const safeModalStateChange = useCallback((isAnswered: boolean, modalHeight: number) => {
    if (onAnswersModalStateChange) {
      onAnswersModalStateChange(isAnswered, modalHeight);
    } else {
      console.warn(`No handler available for ${GCSId}`);
    }
  }, [GCSId, onAnswersModalStateChange]);

  const [selectedAnswer, setSelectedAnswer] = useState<string | null>(null);
  const [showComments, setShowComments] = useState(false);
  const [answersModalVisible, setAnswersModalVisible] = useState(false);
  const [hasAnswered, setHasAnswered] = useState(false);
  const [answersModalHeight, setAnswersModalHeight] = useState(0);
  
  const { userProfile } = useAuth();
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);

  const { showCustomAlert, hideCustomAlert, alertProps, setAlertProps } =
    useCustomAlert();
  const { refetchTrigger, setRefetchTrigger } = useRefetch();

  const profilePicUrl = `https://storage.googleapis.com/clipdle-profile-pics/${uploaderUsername}_thumbnail.jpeg`;

  // Handle answer modal visibility
  const handleOptionButtonClick = () => {
    onVideoInteraction({});
    if (userProfile?.username) setAnswersModalVisible(true);
  };

  // Handle answer selection
  const handleAnswerClick = (answer: string, index: number, isCorrect: boolean) => {
    setSelectedAnswer(answer);
    onVideoInteraction({
      didAnswer: true,
      answeredCorrectly: isCorrect,
      userAnswerIndex: index,
    });
    onAnswerSelect(answer, index, isCorrect);
  };

  const handleAnswerSubmitted = useCallback((answered: boolean, modalHeight: number) => {
    if (hasAnswered !== answered || answersModalHeight !== modalHeight) {
      setHasAnswered(answered);
      setAnswersModalHeight(modalHeight);
      safeModalStateChange(answered, modalHeight);
    }
  }, [hasAnswered, answersModalHeight, safeModalStateChange]);

  // Reset when modal closes
  const handleCloseAnswersModal = () => {
    // First close the modal
    setAnswersModalVisible(false);
    
    // Wait a bit before resetting the answered state to avoid animation conflicts
    setTimeout(() => {
      if (hasAnswered) {
        setHasAnswered(false);
        setAnswersModalHeight(0);
        
        // Always call the callback, it's guaranteed to exist now
        onAnswersModalStateChange(false, 0);
      }
    }, 100);
  };

  const toggleCommentsModal = () => {
    onVideoInteraction({});

    if (userProfile?.username) {
      // Create new state first before using it
      const newState = !showComments;
      
      // Update local state
      setShowComments(newState);
      
      // IMPORTANT: Notify parent component with the same value we just set
      if (onCommentsToggle) {
        onCommentsToggle(newState);
      }
    }
  };

  // When closing the AnswersModal, we should also reset the answered state
  useEffect(() => {
    if (!answersModalVisible && hasAnswered) {
      setHasAnswered(false);
      
      // Always call the callback, it's guaranteed to exist now
      onAnswersModalStateChange(false, 0);
    }
  }, [answersModalVisible, hasAnswered, onAnswersModalStateChange]);

  // Existing animated values for thumbs up/down (for press feedback)
  const thumbsUpScale = useRef(new Animated.Value(1)).current;
  const thumbsDownScale = useRef(new Animated.Value(1)).current;

  // New animated values for hover effects on like/dislike buttons:
  const thumbsUpHover = useRef(new Animated.Value(1)).current;
  const thumbsDownHover = useRef(new Animated.Value(1)).current;

  // Combine the press and hover scales for like button.
  const combinedThumbsUpScale = Animated.multiply(thumbsUpScale, thumbsUpHover);
  const combinedThumbsDownScale = Animated.multiply(thumbsDownScale, thumbsDownHover);

  const [thumbsUpColor, setThumbsUpColor] = useState(isLiked ? 'gold' : 'white');
  const [thumbsDownColor, setThumbsDownColor] = useState(isDisliked ? 'red' : 'white');

  const navigation = useNavigation<StackNavigationProp<StackParamList>>();

  const showAlert = (type: string, title: string, message: string) => {
    let buttons;
    switch (type) {
      case 'copyURL':
        buttons = [{ text: 'OK', onPress: () => hideCustomAlert() }];
        break;
      case 'deleteVideo':
        buttons = [
          { text: 'Cancel', onPress: () => hideCustomAlert() },
          { text: 'OK', onPress: () => deleteVideo() },
        ];
        break;
      default:
        buttons = [{ text: 'OK', onPress: () => hideCustomAlert() }];
    }
    showCustomAlert(title, message, buttons);
  };

  const navigateToUserViewOther = async (userId: string | null) => {
    if (!userProfile?.username) {
      onVideoInteraction({});
      return;
    }
    try {
      const response = await axios.get(`${API_URL}/users/find/${userId}`, {
        withCredentials: true,
      });
      const userProfile = response.data;
      navigation.navigate('UserView', {
        username: userProfile.username,
      });
    } catch (error) {
      Alert.alert('Error', 'Unable to load user profile.');
    }
  };

  const animateThumbsUp = () => {
    Animated.sequence([
      Animated.timing(thumbsUpScale, {
        toValue: 1.2,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(thumbsUpScale, {
        toValue: 1,
        duration: 100,
        useNativeDriver: true,
      }),
    ]).start(() => {
      setThumbsUpColor('gold');
      setThumbsDownColor('white');
    });
  };

  const animateThumbsDown = () => {
    Animated.sequence([
      Animated.timing(thumbsDownScale, {
        toValue: 1.2,
        duration: 100,
        useNativeDriver: true,
      }),
      Animated.timing(thumbsDownScale, {
        toValue: 1,
        duration: 100,
        useNativeDriver: true,
      }),
    ]).start(() => {
      setThumbsUpColor('white');
      setThumbsDownColor('red');
    });
  };

  useFocusEffect(
    useCallback(() => {
      return () => {
        setShowComments(false);
      };
    }, [])
  );

  const handleLike = async () => {
    if (!mongoId) return;
    if (!userProfile?.username) {
      onVideoInteraction({});
      return;
    }
    try {
      animateThumbsUp();
      await axios.patch(
        `${API_URL}/videos/${mongoId}/${uploaderUsername}/${userProfile?.username}/likes/increase`,
        {},
        {
          withCredentials: true,
        }
      );
      setIsLiked(true);
      if (isDisliked) {
        handleUndislike();
      }
    } catch (error) {
      console.error('Error liking video:', error);
    }
  };

  const handleDislike = async () => {
    if (!mongoId) return;
    if (!userProfile?.username) {
      onVideoInteraction({});
      return;
    }
    try {
      animateThumbsDown();
      await axios.patch(
        `${API_URL}/videos/${mongoId}/${userProfile?.username}/dislikes/increase`,
        {},
        { withCredentials: true }
      );
      setIsDisliked(true);
      onVideoInteraction({ didDislike: true });
      onVideoInteraction({ didLike: false });
      if (isLiked) {
        handleUnlike();
      }
    } catch (error) {
      console.error('Error disliking video:', error);
    }
  };

  const handleUndislike = async () => {
    if (!mongoId) return;
    if (!userProfile?.username) {
      onVideoInteraction({});
      return;
    }
    try {
      await axios.patch(
        `${API_URL}/videos/${mongoId}/${userProfile?.username}/dislikes/decrease`,
        {},
        { withCredentials: true }
      );
      setIsDisliked(false);
      onVideoInteraction({ didDislike: false });
      setThumbsDownColor('white');
    } catch (error) {
      console.error('Error undisliking video:', error);
    }
  };

  const handleUnlike = async () => {
    if (!mongoId) return;
    if (!userProfile?.username) {
      onVideoInteraction({});
      return;
    }
    try {
      await axios.patch(
        `${API_URL}/videos/${mongoId}/${uploaderUsername}/${userProfile?.username}/likes/decrease`,
        {},
        { withCredentials: true }
      );
      setIsLiked(false);
      onVideoInteraction({ didLike: false });
      setThumbsUpColor('white');
    } catch (error) {
      console.error('Error unliking video:', error);
    }
  };

  const handleReportCopyright = async () => {
    if (!mongoId) return;
    if (!userProfile?.username) {
      onVideoInteraction({});
      return;
    }
    try {
      await axios.patch(
        `${API_URL}/videos/${mongoId}/reportCopyright`,
        {},
        { withCredentials: true }
      );
    } catch (error) {
      console.error('Error reporting video:', error);
    }
  };

  const handleReportOther = async () => {
    if (!mongoId) return;
    if (!userProfile?.username) {
      onVideoInteraction({});
      return;
    }
    try {
      await axios.patch(
        `${API_URL}/videos/${mongoId}/reportOther`,
        {},
        { withCredentials: true }
      );
    } catch (error) {
      console.error('Error reporting video:', error);
    }
  };

  const copyToClipboard = () => {
    const URL = `https://clipdle.tv/video/${GCSId}/${GCSId}/${uploaderUsername}`;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(URL)
        .then(() => {
          showAlert('copyURL', 'Copied', 'URL has been copied to clipboard');
        })
        .catch((err) => {
          console.error('Could not copy text: ', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = URL;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        showAlert('copyURL', 'Copied', 'URL has been copied to clipboard');
      } catch (err) {
        console.error('Unable to copy: ', err);
      }
      document.body.removeChild(textArea);
    }
  };

  const backToPreviousScreen = () => {
    onVideoInteraction({});
    navigation.goBack();
  };

  const deleteVideo = async () => {
    if (!mongoId) return;
    if (!userProfile?.username) {
      onVideoInteraction({});
      return;
    }
    try {
      await axios.delete(`${API_URL}/videos/${mongoId}`, {
        withCredentials: true,
      });
      navigation.goBack();
    } catch (error) {
      console.error('Error deleting video:', error);
    }
    setRefetchTrigger( refetchTrigger + 1 );
    navigation.goBack();
  };

  const handleDeleteVideo = () => {
    showAlert(
      'deleteVideo',
      'Confirm Delete',
      'Are you sure you want to delete this video?'
    );
  };

  useEffect(() => {
    const fetchStatuses = async () => {
      if (userProfile?.username && mongoId) {
        if (!userProfile?.username) {
          onVideoInteraction({});
          return;
        }
        try {
          const likeResponse = await axios.get(
            `${API_URL}/users/${userProfile.username}/likes/${mongoId}`,
            { withCredentials: true }
          );
          setIsLiked(likeResponse.data.isLiked);
          setThumbsUpColor(likeResponse.data.isLiked ? 'gold' : 'white');

          const dislikeResponse = await axios.get(
            `${API_URL}/users/${userProfile.username}/dislikes/${mongoId}`,
            { withCredentials: true }
          );
          setIsDisliked(dislikeResponse.data.isDisliked);
          setThumbsDownColor(
            dislikeResponse.data.isDisliked ? 'red' : 'white'
          );
        } catch (error) {
          console.error('Error fetching like/dislike status:', error);
          setThumbsUpColor('white');
          setThumbsDownColor('white');
        }
      }
    };
    fetchStatuses();
  }, [mongoId, userProfile?.username]);

  return (
    <>
      {isFromProfile && !answersModalVisible && (
        <View style={styles.headerContainer}>
          <AnimatedHoverableButton
            onPress={backToPreviousScreen}
            style={styles.backButton}
          >
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              size={24}
              color="white"
            />
          </AnimatedHoverableButton>

          {isFromProfile && uploaderUsername === userProfile?.username && (
            <AnimatedHoverableButton
              onPress={handleDeleteVideo}
              style={styles.deleteButton}
            >
              <FontAwesomeIcon icon={faTrash} color="white" size={24} />
            </AnimatedHoverableButton>
          )}
        </View>
      )}
      <View style={styles.overlayContainer}>
        <CustomAlert {...alertProps} />

        { !answersModalVisible && 
          <View style={styles.profileContainer}>
            <Pressable
              onPress={() => navigateToUserViewOther(uploaderUsername)}
              style={styles.profileTouchable}
            >
              <Image source={{ uri: profilePicUrl }} style={styles.profilePic} />
              <Text style={styles.username}>{uploaderUsername}</Text>
            </Pressable>
          </View>
        }

      <Suspense fallback={<View style={styles.loadingContainer}><Text>Loading...</Text></View>}>
        { !answersModalVisible && 
            <AnimatedHoverableButton
              onPress={handleOptionButtonClick}
              style={styles.questionOverlay}
            >
              <Text style={styles.questionText}>{question}</Text>
            </AnimatedHoverableButton>
          }

          <AnswersModal
            isVisible={answersModalVisible}
            onClose={handleCloseAnswersModal}
            question={question || ''}
            answers={answers}
            mongoId={mongoId}
            videoId={GCSId}
            correctAnswer={correctAnswer ?? -1}
            onAnswerSelect={(answer: string, index: number, isCorrect: boolean) => {
              handleAnswerClick(answer, index, isCorrect);
            }}
            onAnswerSubmitted={handleAnswerSubmitted}
          />

          {showComments && (
            <Comments
              uploaderUsername={uploaderUsername}
              mongoId={mongoId}
              isVisible={showComments} // Use the state variable directly
              isBlocked={isBlocked ?? false}
              toggleModal={() => {
                // Create a dedicated close function to avoid inconsistencies
                // IMPORTANT: Always set to false here, don't toggle
                setShowComments(false);
                if (onCommentsToggle) {
                  onCommentsToggle(false);
                }
              }}
            />
          )}
        </Suspense>

        {!answersModalVisible && 
          <View style={styles.actionButtons}>
            {/* Like Button with combined hover and press animations */}
            <Pressable
              style={[styles.iconContainer, isLiked ? styles.disabledButton : {}]}
              onPress={isLiked ? handleUnlike : handleLike}
              testID="like-button"
              onHoverIn={() =>
                Animated.timing(thumbsUpHover, {
                  toValue: 1.1,
                  duration: 150,
                  useNativeDriver: true,
                }).start()
              }
              onHoverOut={() =>
                Animated.timing(thumbsUpHover, {
                  toValue: 1,
                  duration: 150,
                  useNativeDriver: true,
                }).start()
              }
            >
              <Animated.View
                style={{
                  transform: [{ scale: combinedThumbsUpScale }],
                }}
              >
                <FontAwesomeIcon icon={faThumbsUp} color={thumbsUpColor as any} size={30} />
              </Animated.View>
            </Pressable>

            {/* Dislike Button with combined hover and press animations */}
            <Pressable
              style={[styles.iconContainer, isDisliked ? styles.disabledButton : {}]}
              onPress={isDisliked ? handleUndislike : handleDislike}
              testID="dislike-button"
              onHoverIn={() =>
                Animated.timing(thumbsDownHover, {
                  toValue: 1.1,
                  duration: 150,
                  useNativeDriver: true,
                }).start()
              }
              onHoverOut={() =>
                Animated.timing(thumbsDownHover, {
                  toValue: 1,
                  duration: 150,
                  useNativeDriver: true,
                }).start()
              }
            >
              <Animated.View
                style={{
                  transform: [{ scale: combinedThumbsDownScale }],
                }}
              >
                <FontAwesomeIcon icon={faThumbsDown} color={thumbsDownColor as any} size={30} />
              </Animated.View>
            </Pressable>

            <AnimatedHoverableButton
              onPress={toggleCommentsModal}
              style={styles.iconContainer}
            >
              <FontAwesomeIcon icon={faCommentDots} color="white" size={30} />
            </AnimatedHoverableButton>

            <AnimatedHoverableButton
              onPress={copyToClipboard}
              style={styles.iconContainer}
            >
              <FontAwesomeIcon icon={faShareSquare} color="white" size={30} />
            </AnimatedHoverableButton>

            <AnimatedHoverableButton
              onPress={() => setReportModalVisible(true)}
              style={styles.iconContainer}
            >
              <FontAwesomeIcon icon={faFlag} color="white" size={30} />
            </AnimatedHoverableButton>
          </View>
        } 

        <ReportModal
          isVisible={reportModalVisible}
          onClose={() => setReportModalVisible(false)}
          onReportCopyright={handleReportCopyright}
          onReportOther={handleReportOther}
          username={uploaderUsername}
        />
      </View>
    </>
  );
};

export default QuizOverlay;