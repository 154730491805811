// ProfilePictureModal.tsx
import React from 'react';
import {
  Modal,
  View,
  Image,
  Pressable,
  StyleSheet,
  ImageSourcePropType,
} from 'react-native';

interface ProfilePictureModalProps {
  visible: boolean;
  onClose: () => void;
  imageSource: ImageSourcePropType;
}

const ProfilePictureModal: React.FC<ProfilePictureModalProps> = ({
  visible,
  onClose,
  imageSource,
}) => {
  return (
    <Modal
      visible={visible}
      transparent
      animationType="fade"
      onRequestClose={onClose}>
      <Pressable style={styles.modalOverlay} onPress={onClose} activeOpacity={1}>
        <View style={styles.modalContent}>
          <Image source={imageSource} style={styles.fullImage} resizeMode="contain" />
        </View>
      </Pressable>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 10,
  },
  fullImage: {
    width: 300,
    height: 300,
  },
});

export default ProfilePictureModal;
