import React from 'react';
import { Modal, Text, Pressable, StyleSheet, AlertButton, Dimensions } from 'react-native';
import { View } from '../../../context/SafeComponents';

interface CustomAlertProps {
  isVisible: boolean;
  title: string;
  message: string;
  buttons: AlertButton[];
  onClose: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  isVisible,
  title,
  message,
  buttons,
  onClose,
}) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
      presentationStyle="overFullScreen"   // <-- ensures fullscreen overlay
      statusBarTranslucent={true}          // <-- makes StatusBar area transparent on Android
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.title} numberOfLines={2} ellipsizeMode="tail">
            {title}
          </Text>
          <Text style={styles.message} numberOfLines={3} ellipsizeMode="tail">
            {message}
          </Text>
          <View style={styles.buttonContainer}>
            {buttons.map((button, index) => (
              <Pressable
                key={index}
                style={[
                  styles.button,
                  {
                    backgroundColor:
                      button.style === 'cancel' ? '#f44336' : '#4caf50',
                  },
                  buttons.length > 2 && styles.verticalButtons,
                ]}
                onPress={() => {
                  onClose();
                  button.onPress?.();
                }}
              >
                <Text style={styles.buttonText} numberOfLines={1} ellipsizeMode="tail">
                  {button.text}
                </Text>
              </Pressable>
            ))}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const { width } = Dimensions.get('window');
const modalWidth = Math.min(width * 0.85, 320); // 85% of screen width, max 320px

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    // Make sure it's covering the entire screen and on top
    zIndex: 9999,
    position: 'absolute',   
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
  },
  modalView: {
    width: modalWidth,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20, // Reduced padding to give more space for content
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  title: {
    marginBottom: 15,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18, // Slightly smaller font
    fontFamily: 'PressStart2P_400Regular',
    width: '100%', // Ensure text container uses full width
  },
  message: {
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 14, // Slightly smaller font
    fontFamily: 'PressStart2P_400Regular',
    width: '100%', // Ensure text container uses full width
  },
  buttonContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap', // Allow buttons to wrap if needed
    justifyContent: 'space-around',
    width: '100%',
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    minWidth: '30%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5, // Add margin for spacing
  },
  verticalButtons: {
    minWidth: '80%', // Wider buttons when there are more than 2
    marginVertical: 5, // Add vertical spacing
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 12, // Smaller font size for better fit
    fontFamily: 'PressStart2P_400Regular',
  },
});

export default CustomAlert;