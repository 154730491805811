// App.tsx
import React, { useEffect, useRef, useState } from 'react';
import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native';
import MainTabNavigator from './navigators/MainTabNavigator';
import LoginNavigator from './navigators/LoginNavigator';
import { createStackNavigator } from '@react-navigation/stack';
import { AuthProvider, useAuth } from './context/AuthContext';
import { PaperProvider } from './context/SafeProviders';
import { Text, Platform } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import { useFonts, PressStart2P_400Regular } from '@expo-google-fonts/press-start-2p';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { VideoProvider } from './context/VideoContext';
import { useDeepLink } from './context/DeepLinkContext';
import firebase from './firebase';
import { onIdTokenChanged } from "firebase/auth";
import { RefetchProvider } from './context/RefetchContext';
import Admin from './components/Admin/Admin';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { User } from './hooks/user/UserTypes';
import PublicVideoScreen from './components/PublicVideoScreen/PublicVideoScreen';
import type { RootStackParamList } from './linkingConfig';
import LauncherScreen from './components/LauncherScreen/LauncherScreen';
import { API_URL } from '@env';
import { View } from './context/SafeComponents';
import axios from 'axios';
import './warningFilter';

// Create your stack navigator
const RootStack = createStackNavigator<RootStackParamList>();

// Override console.warn and console.error as you had (omitted here for brevity)

const AppContent = () => {
  // All hooks and logic remain here. They will only be rendered
  // after the fonts have loaded.
  const { isAuthenticated, setIsAuthenticated, setUserProfile } = useAuth();
  const { deepLinkUrl } = useDeepLink();
  const navigationRef = useRef<NavigationContainerRef<any>>(null);
  const [isLoading, setIsLoading] = useState(true); 
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminModalVisible, setAdminModalVisible] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'web') {
      document.title = 'Clipdle';
    }
  }, []);

  const checkToken = async () => {
    setIsLoading(true);
    const userProfileString = await AsyncStorage.getItem('userProfile');
    if (userProfileString) {
      const userProfile: User = JSON.parse(userProfileString);
      setUserProfile(userProfile);
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  };

  const checkIfAdmin = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/check`, { withCredentials: true });
      const data = response.data;
      setIsAdmin(data ? data.isAdmin : false);
    } catch (error) {
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      checkIfAdmin();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(firebase.auth, async (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          const expiresIn = new Date(idTokenResult.expirationTime).getTime();
          const tokenDetails = JSON.stringify({
            token: idTokenResult.token,
            expiresIn,
          });
          // You can handle token details if needed.
        });
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    checkToken();
  }, [deepLinkUrl]);

  useEffect(() => {
    if (Platform.OS === 'web' && typeof window !== 'undefined') {
      const handleKeyDown = (event: { ctrlKey: any; altKey: any; key: string; }) => {
        if (event.ctrlKey && event.altKey && event.key === 'a') {  
          checkIfAdmin();
          setAdminModalVisible((prev) => !prev);
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, []);

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ fontFamily: 'PressStart2P_400Regular', fontSize: 24, color: '#fff' }}>
          Loading...
        </Text>
      </View>
    );
  }

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <View style={{ flex: 1, width: '100%', backgroundColor: 'black' }}>
        <RefetchProvider>
          <PaperProvider>
            <VideoProvider>
              <NavigationContainer 
                ref={navigationRef}
                documentTitle={{
                  enabled: true,
                  formatter: () => 'Clipdle',
                }}
                onStateChange={() => {
                  document.title = 'Clipdle';
                }}
              >
                <RootStack.Navigator screenOptions={{ headerShown: false }} initialRouteName="LauncherScreen">
                  <RootStack.Screen name="LauncherScreen" component={LauncherScreen} />
                  {isAuthenticated ? (
                    <RootStack.Screen name="MainTab" component={MainTabNavigator} />
                  ) : (
                    <RootStack.Screen name="LoginNavigatorScreen" component={LoginNavigator} />
                  )}
                  {!isAuthenticated && (
                    <RootStack.Screen name="PublicVideoScreen" component={PublicVideoScreen} />
                  )}
                </RootStack.Navigator>
                {isAdmin && (
                  <Admin visible={adminModalVisible} onClose={() => setAdminModalVisible(false)} />
                )}
              </NavigationContainer>
            </VideoProvider>
          </PaperProvider>
        </RefetchProvider>
      </View>
    </QueryClientProvider>
  );
};

const App = () => {
  // Load fonts at the top level
  let [fontsLoaded] = useFonts({
    PressStart2P_400Regular,
  });

  if (!fontsLoaded) {
    SplashScreen.preventAutoHideAsync();
    return null;
  }

  return <AppContent />;
};

const AppWrapper = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default AppWrapper;
