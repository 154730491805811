// EditProfileModal.tsx
import React, { useEffect, useState } from 'react';
import {
  Modal,
  View,
  Text,
  Pressable,
  TextInput,
  ActivityIndicator,
  StyleSheet,
  Platform,
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil } from '../../optimizations/OptimizedIcons';
import { faTimes} from '../../optimizations/OptimizedIcons';
import { faPlay } from '../../optimizations/OptimizedIcons';
import { faPause } from '../../optimizations/OptimizedIcons';
import axios from 'axios';
import Image from '../UtilityComponents/UniversalImage';
import { API_URL } from '@env';
import { Audio, InterruptionModeIOS, InterruptionModeAndroid } from 'expo-av';

type EditProfileModalProps = {
  visible: boolean;
  onClose: () => void;
  profilePicture: any;
  loading: boolean;
  newBio: string;
  setNewBio: (bio: string) => void;
  updateBio: () => void;
  pickImageAndUpload: () => void;
  editBioMode: boolean;
  setEditBioMode: (value: boolean) => void;
  userProfile?: { 
    username?: string; 
    bio?: string; 
    correctSoundUrl: string | null;
    incorrectSoundUrl: string | null;
  };
};

const MAX_BIO_LENGTH = 200;

const EditProfileModal: React.FC<EditProfileModalProps> = ({
  visible,
  onClose,
  profilePicture,
  loading,
  newBio,
  setNewBio,
  updateBio,
  pickImageAndUpload,
  editBioMode,
  setEditBioMode,
  userProfile,
}) => {
  // Local state for the answer sounds
  const [correctSound, setCorrectSound] = useState<Audio.Sound | null>(null);
  const [incorrectSound, setIncorrectSound] = useState<Audio.Sound | null>(null);
  const [uploadingSound, setUploadingSound] = useState(false);
  const [soundSelectErrorCorrect, setSoundSelectErrorCorrect] = useState<string | null>(null);
  const [soundSelectErrorIncorrect, setSoundSelectErrorIncorrect] = useState<string | null>(null);

  // New state to track if a sound is playing
  const [isCorrectPlaying, setIsCorrectPlaying] = useState(false);
  const [isIncorrectPlaying, setIsIncorrectPlaying] = useState(false);

  // Set up the audio mode once on mount (important for mobile/iOS)
  useEffect(() => {
    Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
      staysActiveInBackground: false,
      interruptionModeIOS: InterruptionModeIOS.DoNotMix,
      shouldDuckAndroid: true,
      interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
    }).catch(error =>
      console.error('Error setting audio mode:', error)
    );
  }, []);

  useEffect(() => {
    const loadSounds = async () => {
      if (userProfile) {
        if (userProfile.correctSoundUrl) {
          const { sound } = await Audio.Sound.createAsync({ uri: userProfile.correctSoundUrl });
          setCorrectSound(sound);
        }
        if (userProfile.incorrectSoundUrl) {
          const { sound } = await Audio.Sound.createAsync({ uri: userProfile.incorrectSoundUrl });
          setIncorrectSound(sound);
        }
      }
    };
    loadSounds();
  }, [userProfile]);

  // Placeholder function to simulate sound picking and uploading.
  const handleSelectSound = async (soundType: 'correct' | 'incorrect') => {
    if (!userProfile?.username) return;

    try {
      setUploadingSound(true);
      if (Platform.OS === 'web') {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'audio/*';
        input.onchange = async (event) => {
          const target = event.target as HTMLInputElement;
          const file = target.files ? target.files[0] : null;
          if (file) {
            const formData = new FormData();
            formData.append('file', file, file.name);
            try {
              const endpoint = `${API_URL}/users/${userProfile.username}/${soundType}/correct-sound`;
              const response = await axios.post(endpoint, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
              });
              const soundUrl =
                soundType === 'correct'
                  ? response.data.correctSoundUrl
                  : response.data.incorrectSoundUrl;
              if (soundType === 'correct') {
                const { sound } = await Audio.Sound.createAsync({ uri: soundUrl });
                setCorrectSound(sound);
              } else {
                const { sound } = await Audio.Sound.createAsync({ uri: soundUrl });
                setIncorrectSound(sound);
              }
              // Clear any previous error
              if (soundType === 'correct') {
                setSoundSelectErrorCorrect(null);
              } else {
                setSoundSelectErrorIncorrect(null);
              }
            } catch (error) {
              console.error('Error: ', error);
              if (soundType === 'correct') {
                setSoundSelectErrorCorrect("Please try again: " + String(error));
              } else {
                setSoundSelectErrorIncorrect("Please try again: " + String(error));
              }
            }
          }
          setUploadingSound(false);
        };
        input.click();
      } else {
        // For native platforms, use an appropriate file picker.
        const dummyFile = new Blob(); // placeholder blob
        const formData = new FormData();
        formData.append('file', dummyFile, 'sound.mp3');

        const endpoint = `${API_URL}/users/${userProfile.username}/${soundType}/correct-sound`;
        const response = await axios.post(endpoint, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        });
        const soundUrl =
          soundType === 'correct'
            ? response.data.correctSoundUrl
            : response.data.incorrectSoundUrl;
        if (soundType === 'correct') {
          const { sound } = await Audio.Sound.createAsync({ uri: soundUrl });
          setCorrectSound(sound);
        } else {
          const { sound } = await Audio.Sound.createAsync({ uri: soundUrl });
          setIncorrectSound(sound);
        }
        setUploadingSound(false);
        if (soundType === 'correct') {
          setSoundSelectErrorCorrect(null);
        } else {
          setSoundSelectErrorIncorrect(null);
        }
      }
    } catch (error) {
      console.error('Error uploading sound:', error);
      if (soundType === 'correct') {
        setSoundSelectErrorCorrect("Please try again: " + String(error));
      } else {
        setSoundSelectErrorIncorrect("Please try again: " + String(error));
      }
      setUploadingSound(false);
    }
  };

  // Updated function to handle play/pause toggle with added checks
  const handlePlayPauseSound = async (sound: Audio.Sound | null, type: 'correct' | 'incorrect') => {
    if (!sound) {
      console.error('Sound object is null.');
      return;
    }
    try {
      const status = await sound.getStatusAsync();
      // Check that the sound is loaded before proceeding.
      if (!status.isLoaded) {
        console.error('Sound is not loaded yet.');
        return;
      }
      if (status.isPlaying) {
        await sound.pauseAsync();
        type === 'correct' ? setIsCorrectPlaying(false) : setIsIncorrectPlaying(false);
      } else {
        await sound.playAsync();
        type === 'correct' ? setIsCorrectPlaying(true) : setIsIncorrectPlaying(true);
        // Reset the playing state when playback finishes.
        sound.setOnPlaybackStatusUpdate((playbackStatus) => {
          if (playbackStatus.isLoaded && playbackStatus.didJustFinish) {
            type === 'correct' ? setIsCorrectPlaying(false) : setIsIncorrectPlaying(false);
          }
        });
      }
    } catch (error) {
      console.error('Error toggling playback:', error);
    }
  };

  return (
    <Modal visible={visible} animationType="slide" transparent>
      <View style={styles.modalOverlay}>
        <View style={styles.modalContainer}>
          {/* Header */}
          <View style={styles.header}>
            <Pressable onPress={onClose}>
              <FontAwesomeIcon icon={faTimes} size={24} color="#fff" />
            </Pressable>
            <Text style={styles.headerTitle}>Edit Profile</Text>
            <View style={{ width: 24 }} />
          </View>

          <View style={styles.content}>
            {/* Profile Picture Section */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Profile Picture</Text>
              <View style={styles.profilePictureContainer}>
                <View style={styles.photoWrapper}>
                  {loading ? (
                    <ActivityIndicator size="large" color="#bbb" />
                  ) : (
                    <Image source={profilePicture} style={styles.avatar} />
                  )}
                </View>
                <Pressable
                  onPress={pickImageAndUpload}
                  style={styles.changePhotoButton}
                >
                  <Text style={styles.changePhotoButtonText}>Change Photo</Text>
                </Pressable>
              </View>
            </View>

            {/* Bio Section */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Bio</Text>
              <View style={styles.bioContainer}>
                {editBioMode ? (
                  <>
                    <View style={styles.bioInputWrapper}>
                      <TextInput
                        style={styles.bioInput}
                        onChangeText={setNewBio}
                        value={newBio}
                        placeholder={userProfile?.bio || 'Enter your bio'}
                        placeholderTextColor="#888"
                        multiline
                      />
                      <Text
                        style={[
                          styles.charCounter,
                          newBio.length > MAX_BIO_LENGTH && styles.charCounterError,
                        ]}
                      >
                        {newBio.length}/{MAX_BIO_LENGTH}
                        {newBio.length > MAX_BIO_LENGTH ? ' !' : ''}
                      </Text>
                    </View>
                    <Pressable onPress={updateBio} style={styles.saveButton}>
                      <Text style={styles.saveButtonText}>Save</Text>
                    </Pressable>
                  </>
                ) : (
                  <View style={styles.bioDisplay}>
                    <Text style={styles.bioText}>{userProfile?.bio}</Text>
                    <Pressable
                      onPress={() => setEditBioMode(true)}
                      style={styles.iconButton}
                    >
                      <FontAwesomeIcon icon={faPencil} size={20} color="#fff" />
                    </Pressable>
                  </View>
                )}
              </View>
            </View>

            {/* Answer Sounds Section */}
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Answer Sounds</Text>
              <View style={styles.soundRow}>
                <Text style={styles.soundLabel}>Correct Answer Sound:</Text>
                {uploadingSound ? (
                  <ActivityIndicator size="small" color="#0094F7" />
                ) : correctSound ? (
                  <View style={styles.soundControls}>
                    <Pressable
                      onPress={() => handlePlayPauseSound(correctSound, 'correct')}
                      style={styles.playButton}
                    >
                      <FontAwesomeIcon
                        icon={isCorrectPlaying ? faPause : faPlay}
                        size={18}
                        color="#fff"
                      />
                    </Pressable>
                  </View>
                ) : null }
                <Pressable
                  onPress={() => handleSelectSound('correct')}
                  style={styles.selectSoundButton}
                >
                  <Text style={styles.selectSoundButtonText}>Select</Text>
                </Pressable>
              </View>
              {soundSelectErrorCorrect && (
                <Text style={{ color: 'red', fontSize: 12 }}>{soundSelectErrorCorrect}</Text>
              )}

              <View style={[styles.soundRow, { marginTop: 15 }]}>
                <Text style={styles.soundLabel}>Incorrect Answer Sound:</Text>
                {uploadingSound ? (
                  <ActivityIndicator size="small" color="#0094F7" />
                ) : incorrectSound ? (
                  <View style={styles.soundControls}>
                    <Pressable
                      onPress={() => handlePlayPauseSound(incorrectSound, 'incorrect')}
                      style={styles.playButton}
                    >
                      <FontAwesomeIcon
                        icon={isIncorrectPlaying ? faPause : faPlay}
                        size={18}
                        color="#fff"
                      />
                    </Pressable>
                  </View>
                ) : null}
                <Pressable
                  onPress={() => handleSelectSound('incorrect')}
                  style={styles.selectSoundButton}
                >
                  <Text style={styles.selectSoundButtonText}>Select</Text>
                </Pressable>
              </View>
              {soundSelectErrorIncorrect && (
                <Text style={{ color: 'red', fontSize: 12 }}>{soundSelectErrorIncorrect}</Text>
              )}
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.8)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    width: '90%',
    backgroundColor: '#2c2c2c',
    borderRadius: 12,
    padding: 20,
    shadowColor: '#000',
    shadowOpacity: 0.4,
    shadowRadius: 10,
    elevation: 8,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25,
  },
  headerTitle: {
    fontSize: 20,
    fontFamily: 'PressStart2P_400Regular',
    fontWeight: '600',
    color: '#fff',
  },
  content: {
    width: '100%',
  },
  section: {
    marginBottom: 25,
  },
  sectionTitle: {
    fontSize: 16,
    fontFamily: 'PressStart2P_400Regular',
    fontWeight: '500',
    color: '#fff',
    marginBottom: 8,
  },
  /* Profile Picture Section */
  profilePictureContainer: {
    flexDirection: 'row',
    backgroundColor: '#262626',
    borderRadius: 8,
    alignItems: 'center',
    padding: 10,
  },
  photoWrapper: {
    flex: 1,
    justifyContent: 'center',
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  changePhotoButton: {
    backgroundColor: '#0094F7',
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 6,
    fontFamily: 'PressStart2P_400Regular',
  },
  changePhotoButtonText: {
    color: '#fff',
    fontWeight: '500',
    fontFamily: 'PressStart2P_400Regular',
  },
  /* Bio Section */
  bioContainer: {
    width: '100%',
  },
  bioDisplay: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#3a3a3a',
    padding: 12,
    borderRadius: 8,
  },
  bioText: {
    flex: 1,
    fontSize: 16,
    color: '#fff',
    fontFamily: 'PressStart2P_400Regular',
  },
  bioInputWrapper: {
    position: 'relative',
    backgroundColor: '#3a3a3a',
    borderRadius: 8,
    padding: 12,
  },
  bioInput: {
    fontSize: 16,
    color: '#fff',
    paddingRight: 50,
    fontFamily: 'PressStart2P_400Regular',
  },
  charCounter: {
    position: 'absolute',
    right: 12,
    bottom: 8,
    fontSize: 12,
    color: '#ccc',
    fontFamily: 'PressStart2P_400Regular',
  },
  charCounterError: {
    color: 'red',
    fontFamily: 'PressStart2P_400Regular',
  },
  saveButton: {
    backgroundColor: '#0094F7',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 6,
    alignSelf: 'flex-end',
    marginTop: 8,
  },
  saveButtonText: {
    color: '#fff',
    fontFamily: 'PressStart2P_400Regular',
    fontWeight: '500',
    fontSize: 16,
  },
  iconButton: {
    marginLeft: 12,
  },
  /* Answer Sounds Section */
  soundRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  soundLabel: {
    fontSize: 14,
    fontFamily: 'PressStart2P_400Regular',
    color: '#fff',
    flex: 1,
  },
  selectSoundButton: {
    backgroundColor: '#0094F7',
    paddingVertical: 6,
    paddingHorizontal: 10,
    borderRadius: 6,
  },
  selectSoundButtonText: {
    color: '#fff',
    fontSize: 14,
    fontFamily: 'PressStart2P_400Regular',
  },
  soundControls: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  playButton: {
    backgroundColor: '#0094F7',
    padding: 8,
    borderRadius: 30,
    marginRight: 8,
  },
  waveformPlaceholder: {
    backgroundColor: '#444',
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  waveformText: {
    fontSize: 10,
    color: '#ccc',
    fontFamily: 'PressStart2P_400Regular',
  },
});

export default EditProfileModal;
