// LazyComponents.tsx
import React, { Suspense } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';

// Loading component to show while lazy components are loading
export const LoadingScreen = () => (
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'black' }}>
    <ActivityIndicator size="large" color="#007AFF" />
    <Text style={{ color: 'white', marginTop: 10 }}>Loading...</Text>
  </View>
);

// Lazy load common components used across multiple navigators
export const LazyLogin = React.lazy(() => import('../components/Login/Login'));
export const LazySignUp = React.lazy(() => import('../components/SignUp/SignUp'));
export const LazyForgotPassword = React.lazy(() => import('../components/ForgotPassword/ForgotPassword'));
export const LazyUserSearch = React.lazy(() => import('../components/UserSearch/UserSearch'));
export const LazyUserView = React.lazy(() => import('../components/UserView/UserView'));
export const LazyUserViewOther = React.lazy(() => import('../components/UserViewOther/UserViewOther'));
export const LazyUserViewSelf = React.lazy(() => import('../components/UserViewSelf/UserViewSelf'));
export const LazyQuizViewerProfile = React.lazy(() => import('../components/QuizViewerProfile/QuizViewerProfile'));
export const LazyQuiz = React.lazy(() => import('../components/Quiz/Quiz'));
export const LazySubmitClip = React.lazy(() => import('../components/SubmitClip/SubmitClip'));
export const LazyQuestionForm = React.lazy(() => import('../components/QuestionForm/QuestionForm'));

// Higher-order component to wrap lazy-loaded components with Suspense
export const withSuspense = (Component: React.LazyExoticComponent<any>) => {
  return (props: any) => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

// Export wrapped components ready to use in navigators
export const Login = withSuspense(LazyLogin);
export const SignUp = withSuspense(LazySignUp);
export const ForgotPassword = withSuspense(LazyForgotPassword);
export const UserSearch = withSuspense(LazyUserSearch);
export const UserView = withSuspense(LazyUserView);
export const UserViewOther = withSuspense(LazyUserViewOther);
export const UserViewSelf = withSuspense(LazyUserViewSelf);
export const QuizViewerProfile = withSuspense(LazyQuizViewerProfile);
export const Quiz = withSuspense(LazyQuiz);
export const SubmitClip = withSuspense(LazySubmitClip);
export const QuestionForm = withSuspense(LazyQuestionForm);