// warningFilter.ts
// This file suppresses specific React Native Web deprecation warnings

// Store the original console.warn
const originalConsoleWarn = console.warn;

// Override console.warn to filter out specific deprecation warnings
console.warn = function(...args) {
  // Check if this is the specific warning we want to filter
  if (
    args[0] && 
    typeof args[0] === 'string' && 
    (args[0].includes('pointerEvents is deprecated') || 
     args[0].includes('style.resizeMode is deprecated') ||
     args[0].includes('"shadow*" style props are deprecated') ||
     args[0].includes('"textShadow*" style props are deprecated')
  )) {
    // Ignore this warning
    return;
  }
  
  // Pass through all other warnings
  return originalConsoleWarn.apply(console, args);
};