// LoadingComponent.tsx
import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { Image } from '../../context/SafeComponents';
import { View } from '../../context/SafeComponents';

// Adjust the path to your GIF asset as needed
const wavingGif = require('../../assets/gifs/clipdle-waving.webp')

const LoadingComponent: React.FC = () => {
  return (
    <View style={styles.container}>
      <View style={styles.gifContainer}>
        <Image source={wavingGif} style={styles.gif} resizeMode="contain" />
      </View>
      <Text style={styles.loadingText}>
        Hold on—your clip is just warming up!
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000', // You can change the background color if desired
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  gifContainer: {
    marginBottom: 20,
  },
  gif: {
    width: 150,
    height: 150,
  },
  loadingText: {
    fontSize: 18,
    fontFamily: 'PressStart2P_400Regular',
    color: '#fff',
    textAlign: 'center',
  },
});

export default LoadingComponent;
