// events.ts
import mitt from 'mitt';

// Define our event types for better type safety
export type ModalEvent = {
  videoId: string;
  isAnswered: boolean;
  isVisible: boolean;
  modalHeight: number;
  numberAnswers: number;
};

// Create and export a shared emitter instance
export const emitter = mitt<{
  // Define typed events
  'modal-state-change': ModalEvent;
}>();