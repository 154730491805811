
import React, { useState, useEffect } from 'react';
import { Text, StyleSheet, Pressable, ListRenderItemInfo, Dimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { View } from '../../context/SafeComponents';
import { RouteProp } from '@react-navigation/native';
import { StackParamList } from '../../navigators/navigationTypes';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faArrowAltCircleLeft } from '../../optimizations/OptimizedIcons';
import { faListDots } from '../../optimizations/OptimizedIcons';
import { StackNavigationProp } from '@react-navigation/stack';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import UsersListModal from '../UsersListModal/UsersListModal';
import { useGetUser } from '../../hooks/user/useGetUser';

import Image from '../UtilityComponents/UniversalImage';
import UserActionModal from '../UserActionModal/UserActionModal'; // Import the modal

// Define the type for the props expected by UserViewOther component
type UserViewOtherProps = {
  route: RouteProp<StackParamList, 'UserViewOther'>;
};
import { User } from '../../hooks/user/UserTypes';
import { API_URL } from '@env';
import VideoGallery from '../VideoGallery/VideoGallery';
import ProfilePictureModal from '../ProfilePictureModal/ProfilePictureModal';

const UserViewOther: React.FC<UserViewOtherProps> = ({ route }) => {

  const { userProfile } = route.params;
  const { userProfile: ownUserProfile, updateUserFollowing, updateUserFollowingCount, updateBlockedUsers, getToken } = useAuth();
  const [isFollowing, setIsFollowing] = useState(false);
  const isOwnProfile = (userProfile.username === ownUserProfile?.username);

  const [showFollowersModal, setShowFollowersModal] = useState(false); // State to show/hide followers modal
  const [showFollowingModal, setShowFollowingModal] = useState(false); // State to show/hide following modal
  const [showUserActionModal, setShowUserActionModal] = useState(false);
  const [isProfileModalVisible, setProfileModalVisible] = useState(false);
  const [followersCount, setFollowersCount] = useState<number | null>(0);
  const [followingCount, setFollowingCount] = useState<number | null>(0);
  const [followersList, setFollowersList] = useState<string[] | null | undefined>([]);
  const [followingList, setFollowingList] = useState<string[] | null | undefined>([]);
  const [showBlur, setShowBlur] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [ userProfileData, setUserProfileData ] = useState<User | null>(null);
  const { loading, fetchUser } = useGetUser( userProfile.username );
  const navigation = useNavigation<StackNavigationProp<StackParamList>>();

  useEffect (() => {
    const fetchUserProfileData = async () => {
      const userProfileDataTemp = await fetchUser();
      if (userProfileDataTemp != null) {
        setUserProfileData(userProfileDataTemp);
        setFollowersCount(userProfileDataTemp?.followersCount);
        setFollowingCount(userProfileDataTemp?.followingCount);
        setFollowersList(userProfileDataTemp?.followersUserIds);
        setFollowingList(userProfileDataTemp?.followingUserIds);
      }
    };
    fetchUserProfileData();
  } , []);

  // Sync local following state with the context's following list
  useEffect(() => {
    if (ownUserProfile?.followingUserIds && userProfile.username) {
      setIsFollowing(ownUserProfile.followingUserIds.includes(userProfile.username));
    }
  }, [ownUserProfile?.followingUserIds, userProfile.username]);

  // Function to handle opening the user action modal
  const openUserActionModal = () => {
    setShowUserActionModal(true);
  };

  const fetchIsUserBlocked = () => {
    let isBlockedLocal = ownUserProfile?.blockedUserIds.includes(userProfile.username);
    if(isBlockedLocal){
      setIsBlocked(isBlockedLocal);
      setShowBlur(true);
    } 
  };

  const blockUser = async () => {
    try {
      await axios.put(`${API_URL}/users/${ownUserProfile?.username}/block/${userProfile.username}`, 
        {},
        {
          withCredentials: true,
        }
      );
      
      setShowUserActionModal(false);

      // Update the local context using updateBlockedUsers
      if (ownUserProfile && userProfile) {
        updateBlockedUsers([...ownUserProfile.blockedUserIds, userProfile.username]);
        setIsBlocked(true);
        setShowBlur(true);
      }
  
    } catch (error: any) {
      console.error(error.response);
    }
  };

  const handleViewAnyway = () => {
    setShowBlur(false);
  };

  // router.put('/users/:username/block/:otherUsername'
  const unblockUser = async () => {
    try {
      await axios.put(`${API_URL}/users/${ownUserProfile?.username}/unblock/${userProfile.username}`, 
        {},
        {
          withCredentials: true,
        }
      );
  
      // Update the local context using updateBlockedUsers
      if (ownUserProfile && userProfile) {
        const updatedBlockedUserIds = ownUserProfile.blockedUserIds.filter(id => id !== userProfile.username);
        updateBlockedUsers(updatedBlockedUserIds);
        setIsBlocked(false);
        setShowBlur(false);
      }
  
    } catch (error: any) {
      console.error(error.response);
    }
  };

  const reportUser = async () => {
    try {
      await axios.patch(`${API_URL}/users/report/${userProfile.username}`,
        {}, 
        {
          withCredentials: true,
        }
      );
      setShowUserActionModal(false);
    } catch (error: any) {
      console.error(error.response);
    }
  };

  // Check if the user is blocked on mount/update
  useEffect(() => {
    if (!ownUserProfile?.username || !userProfile.username) return;
    fetchIsUserBlocked();
  }, [ownUserProfile?.username, userProfile.username]);

  const toggleFollow = async () => {
    if (isFollowing) {
      await handleUnfollow();
    } else {
      await handleFollow();
    }
  };

  const backToPreviousScreen = () => {
    navigation.goBack();
  }

  const handleFollow = async () => {
    try {
      // Call the backend to follow the user
      const followResponse = await axios.put(
          `${API_URL}/users/${ownUserProfile?.username}/follow/${userProfile.username}`,
          {}, // Empty request body since you don't need to send data
          {
              withCredentials: true, // Ensure credentials like cookies are sent
          }
      );
      
      setIsFollowing(true);
      setFollowersCount(followersCount ? followersCount + 1 : 1);
      if (ownUserProfile?.username) {
        setFollowersList([...(followersList || []), ownUserProfile.username]);
      }

      // Update local AuthContext
      if (ownUserProfile && ownUserProfile.followingUserIds && ownUserProfile.followingCount) {
        const updatedFollowing = [...ownUserProfile.followingUserIds, userProfile.username];
        updateUserFollowing(updatedFollowing);
        updateUserFollowingCount(ownUserProfile.followingCount + 1);
      }
      
      // Provide feedback to the user
    } catch (error) {
      console.error('Error following user:', error);
      // Handle the error, e.g., show an alert to the user
    }
  };
  
  const handleUnfollow = async () => {
    try {

      // Call the backend to unfollow the user
      const unfollowResponse = await axios.put(
        `${API_URL}/users/${ownUserProfile?.username}/unfollow/${userProfile.username}`, 
        {},
        {
          withCredentials: true,
        }
      );
      
      setIsFollowing(false);
      setFollowersCount(followersCount ? followersCount - 1 : 0);
      if (ownUserProfile) {
        setFollowersList(followersList?.filter(id => id !== ownUserProfile.username));
      }

      // Update local AuthContext
      if (ownUserProfile && ownUserProfile.followingUserIds && ownUserProfile.followingCount) {
        const updatedFollowing = ownUserProfile.followingUserIds.filter(id => id !== ownUserProfile.username);
        updateUserFollowing(updatedFollowing);
        updateUserFollowingCount(ownUserProfile.followingCount - 1);
      }

      // Provide feedback to the user
    } catch (error) {
      console.error('Error unfollowing user:', error);
      // Handle the error, e.g., show an alert to the user
    }
  };

  const navigateToViewer = (videoId: string) => {
    
    navigation.navigate('QuizViewerProfile', {
      videoIds: [videoId], 
      initialVideoId: videoId,  // Add this line
      username: userProfile.username
    });
  }

  // Render followers and following counts
  const renderCounts = () => (
    <View style={styles.countsContainer}>
      <Pressable onPress={() => setShowFollowersModal(true)}>
        <Text style={{color: 'white', fontFamily: 'PressStart2P_400Regular'}}>{followersCount} Followers</Text>
      </Pressable>
      <Pressable onPress={() => setShowFollowingModal(true)}>
        <Text style={{color:'white', fontFamily: 'PressStart2P_400Regular'}}>{followingCount} Following</Text>
      </Pressable>
    </View>
  );

  const renderVideoThumbnail = ({ item }: ListRenderItemInfo<string>) => {
    
    const thumbnailUri = `https://storage.googleapis.com/clipdle_videos_thumbnails/${item}_thumbnail.jpeg`;
    return (
      <Pressable onPress={() => navigateToViewer(item)} style={styles.clipImageContainer}>
        <Image
          source={{ uri: thumbnailUri }}
          defaultSource={require('../../assets/images/Ex1.jpg')} // Fallback image
          style={styles.clipImage}
        />
      </Pressable>
    );
  };

  const renderProfileHeader = () => {
    return (
      <View style={styles.profileHeader}>
        <Text style={styles.username}>{userProfile.username}</Text>
        <Text style={styles.bio}>{userProfile.bio}</Text>

        {!isOwnProfile && (
          <View>
            <Pressable onPress={toggleFollow} disabled={isOwnProfile} style={styles.followButton}>
              <Text style={styles.followButtonText}>{isFollowing ? 'Following' : 'Follow'}</Text>
            </Pressable>

            <UserActionModal
              isVisible={showUserActionModal}
              onClose={() => setShowUserActionModal(false)}
              isBlocked={isBlocked}
              onBlock={blockUser}
              onUnblock={unblockUser}
              onReport={reportUser}
            />
          </View>
        )}
      </View>
    );
  };

  const renderProfileImage = () => {
    return (
      <Pressable onPress={() => setProfileModalVisible(true)} style={styles.imageWrapper}>
        <Image
          source={loading ? require('../../assets/images/avatar.jpg') : userProfile.profilePicture}
          style={styles.avatar}
        />
      </Pressable>
    );
  };
  

  return (
    <View style={styles.container} >
      <View style={styles.header}>
        <Pressable onPress={backToPreviousScreen} style={styles.backButton}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size={24} color="white"/>
        </Pressable>
        {renderProfileImage()}
        {/* <Image
          source={userProfile.profilePicture ? { uri: userProfile.profilePicture } : require('../../assets/images/avatar.jpg')}
          style={styles.avatar}
        /> */}
        <Pressable onPress={openUserActionModal} style={styles.userActionStyle}>
          <FontAwesomeIcon icon={faListDots} color='white' size={24}></FontAwesomeIcon>
        </Pressable>
      </View>

      {renderProfileHeader()}
      {renderCounts()}

      {/* Followers Modal */}
      {followersCount != null && followersCount > 0 && (
        <UsersListModal
          users={followersList}
          isVisible={showFollowersModal}
          toggleModal={() => setShowFollowersModal(false)}
        />
      )}

      {followingCount != null && followingCount > 0 && (
        <UsersListModal
          users={followingList}
          isVisible={showFollowingModal}
          toggleModal={() => setShowFollowingModal(false)}
        />
      )}

      <ProfilePictureModal
        visible={isProfileModalVisible}
        onClose={() => setProfileModalVisible(false)}
        imageSource={loading ? require('../../assets/images/avatar.jpg') : userProfileData?.profilePicture}
      />
      
      {/* FlatList for displaying video thumbnails */}

      <View style={{flex: 1, width: '100%'}}>

        <VideoGallery
          videos={userProfileData?.uploadedVideos}
          onVideoPress={navigateToViewer}
          onRefresh={fetchUser}
          refreshing={loading}
        />
      
        {isBlocked && showBlur && (
          <View style={styles.blurOverlay}>
            <Text style={styles.reportedWarning}>This user has been blocked</Text>
            <Pressable style={styles.watchAnywayButton} onPress={handleViewAnyway}>
              <Text style={styles.watchAnywayText}>View Anyway</Text>
            </Pressable>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 0,
    paddingTop: 10,
    color: 'black',
    backgroundColor: 'black',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  backButton: {
    padding: 10,
  },
  spacer: {
    width: 24, // Make sure this matches the size of the FontAwesomeIcon to balance the layout
  },
  profileHeader: {
    alignItems: 'center',
    marginBottom: 16,
  },
  fullScreenBlurOverlay: {
    ...StyleSheet.absoluteFillObject, // This will cover the entire area behind the FlatList
    backgroundColor: 'rgba(0, 0, 0, 0.95)', // Adjust opacity for desired blur effect
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 502, // Ensure it is above the FlatList
  },
  actionButton: {
    // Style for the User Actions button
    padding: 10,
    backgroundColor: '#2069e0',
    borderRadius: 5,
    alignSelf: 'center',
    marginTop: 10,
  },
  actionButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
    // Remove marginBottom if not needed
  },
  countsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
  userActionStyle: {
    padding: 10,
  },
  username: {
    fontSize: 18,
    fontFamily: 'PressStart2P_400Regular',
    fontWeight: 'bold',
    color: 'white',
    marginBottom: 8,
  },
  bio: {
    fontSize: 14,
    fontFamily: 'PressStart2P_400Regular',
    color: 'white',
    marginBottom: 8,
  },
  followButton: {
    // Similar styles as in UserView
    backgroundColor: '#1DA1F2',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
  },
  followButtonText: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'PressStart2P_400Regular'
  },
  clipImageContainer: {
    width: 200, // Fixed width for each item, adjust as needed
    maxWidth: Dimensions.get('window').width / 3, // One clip per row
    aspectRatio: 1,
    padding: 2,
  },
  clipImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  blurOverlay: {
    ...StyleSheet.absoluteFillObject, // This will cover the entire video
    backgroundColor: 'rgba(0, 0, 0, 0.95)', // Adjust opacity for desired blur effect
    justifyContent: 'center',
    zIndex: 502,
    alignItems: 'center',
  },
  reportedWarning: {
    color: 'red',
    fontSize: 18,
    fontFamily: 'PressStart2P_400Regular',
    textAlign: 'center',
    padding: 20,
  },
  watchAnywayButton: {
    backgroundColor: 'blue', // Button color
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 5,
  },
  watchAnywayText: {
    color: '#FFFFFF', // Text color
    fontSize: 16,
    fontFamily: 'PressStart2P_400Regular',
  },
  imageWrapper: {
    position: 'relative',
  },
  profileImageContainer: {
    position: 'absolute', // Position the avatar absolutely
    top: 10, // Distance from the top of the header
    left: 0,
    right: 0,
    alignItems: 'center', // This will center the avatar
    zIndex: 1, // Make sure it's in front of the bio
  },
  // You might want to keep or adjust these styles as needed
});

export default UserViewOther;
