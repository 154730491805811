import { StyleSheet, Dimensions } from "react-native";

const styles = StyleSheet.create({
  // Similar styles as in UserSearch.tsx, adjust as needed
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  actionDropdown: {
    // Styles for the dropdown menu
    padding: 10,
    backgroundColor: '#f0f0f0',
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    width: '100%',
  },
  userItem: {
    padding: 5,
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    flexDirection: 'row',
  },
  userText: {
    color: 'black',
    fontFamily: 'PressStart2P_400Regular',
    flex: 2,
    top: 8,
  },
  commentAvatar: {
    width: 32,
    height: 32,
    borderRadius: 16,
    marginRight: 8,
  },
  selectedUserItem: {
    padding: 5,
    borderBottomColor: 'blue', // Example: Change the border color to indicate selection
    borderBottomWidth: 2, // Increase the border width for emphasis
    flexDirection: 'row',
    backgroundColor: '#e0e0e0', // Light background color for the selected user
  },
});

export default styles;
